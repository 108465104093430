.csBtn__sm {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  color: @black;
  .opacity(.4);
  font-size: 1.1rem;
  &:hover {
    .opacity(.6);
  }
}

/* poll css */

/* ======================== Overwrite end ===================== */

/* ---- Alerts & Error Message Start ---- */
.message {
  padding: 5px 15px;
  border-radius: 2px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.message_error {
  color: @white !important;
  background-color: @bloodred;
  display: block;
  float: left;
  width: 100%;
}
/* ---- Alerts & Error Message End ---- */

.alert {
  padding: 5px 15px;
  ul{
    margin: 0;
    padding: 0;
  }
}
/* ---- Select style ---- */

.dropdown-content,
.dropdown-menu  {
  z-index: 998;

  & >li {
    &.disabled {
      display: none;
    }
    &:hover {
      background-color: #eee;
    }
    span {
      font-size: 1rem;
      color: @darkGrey;
      display: block;
      padding: 0.7rem 0.7rem;
    }
  }
}

/* ---- Select Dropdown Start ---- */
.select-wrapper {
  .select-wrapper {
    border: 0;
    padding-right: 0;
    span.caret{
      right:0px;
    }
  }
  ul {
     top: 45px !important;
    max-height: 200px;
    max-height: 30vh;
    overflow-y: auto;
    width:100% !important;
    max-width: inherit;
    li{
      &.selected{
        background-color: @lighterGrey;
      }
    }
  }
  input {
    &.select-dropdown {
      border-bottom: 0;
      padding-right: 15px;
      .text-overflow();
      border-bottom: 1px solid @lightGrey;
      box-sizing: border-box;
    }
  }
}
/* ---- Select Dropdown Start ---- */

input[type=text] {
  &.error {
    border-bottom: 1px solid red;
    box-shadow: 0 1px 0 0 red;
    &:focus:not([readonly]){
      border-color:red;
      box-shadow: 0 1px 0 0 red;
    }
  }
}

input[type=password] {
  &.error {
    border-bottom: 1px solid red;
    .box-shadow(0 1px 0 0 red);

  }
}

input[type=email] {
  &.error {
    border-bottom: 1px solid red;
    .box-shadow(0 1px 0 0 red);
  }
}

.error {
  [type="checkbox"] + label:before {
    border: 2px solid red;
  }
  [type="checkbox"]:checked + label:before {
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid red;
    border-bottom: 2px solid red;
  }
  [type="radio"]:not(:checked) + label:before {
    border: 2px solid red;
  }
}
/* --------- Input Error Color End --------- */

.emailType, .phoneType, .otherType {
  .select-wrapper {
    margin-top: 6px;
  }
}

.shariff{
  .info{
    border: 0
  }
}

legend{
  font-weight: 300;
}


// Registration form
.select-wrapper.error{
  input.select-dropdown{
    border-bottom: 2px solid red;
  }
}
.alert-error {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442;
}

.dropdown.dropdown-above.open > .dropdown-menu{
  top: auto;
  bottom: 100%;
}



/* NG */



/* Button */

@btn-height: 2.6rem;
@btn-font: ~"400 0.8rem/1.25" @font-main;


/* Button Primary */

@btn-primary-bg: #333;
@btn-primary-text-color: #fff;

@btn-primary-bg--active: #000;


/* Button Secondary*/

@btn-secondary-bg: #fff;
@btn-secondary-text-color: #444;

@btn-secondary-bg--active: @darkGrey;
@btn-secondary-text-color--active: #fff;

@btn-secondary-border: 1px #e5e5e5 solid;


/* Share Buttons */

@shariff-button-bg: #fff;
@shariff-button-color: @darkGrey;
@shariff-button-border: 1px rgba(0, 0, 0, 0.1) solid;

@shariff-button-bg--hover: #e5e5e5;
@shariff-button-color--hover: @darkGrey;

/* Input Fields */

@input-font: ~"300 1rem/1.1" @font-main;
@input-label-font: ~"300 1rem/1.5" @font-main;

@input-color: @darkGrey;
@input-bg-color: #fff;
@input-caret-color: #ccc;

@input-height: 2.6rem;

@input-border: ~"1px solid" @border-gray;
@input-focus-border-color: #404040;
@input-border-radius: 2px;


/* Checkbox + Radio */

@checkbox-radio-color: #000;


/* Checkbox + Radio label */

@checkbox-radio-label-color: @darkGrey;
@checkbox-label-font:  ~"300 1rem/1.45" @font-main;

@radio-label-font: ~"300 1rem/1.56" @font-main;

/* Buttons */

.btn{
    font: @btn-font;
    min-height: @btn-height;
    padding: 0.8rem 2.13rem;
    text-decoration: none;
    &:hover,
    &:focus,
    &.active{
      text-decoration: none;
    }
    i + span{
      padding-left: 4px;
    }
}

/* Button Primary*/

.btn.btn-primary{
    color: @btn-primary-text-color!important;
    background: @btn-primary-bg;
    box-shadow: none;
    
    

  &:hover,
  &:focus,
  &.active{
    background: @btn-primary-bg--active!important;
  }
}


/* Button Secondary */

.btn.btn-secondary{
    color: @btn-secondary-text-color!important;
    background: @btn-secondary-bg;
    border: @btn-secondary-border;
    box-shadow: none;

  &:hover,
  &:focus,
  &.active{
    color: @btn-secondary-text-color--active!important;
    background: @btn-secondary-bg--active!important;
    
  }
}

.btn.btn-reset{
  margin: 0;
}

/* Button GH Count */

.btn{
  .gh-count{
    border: 1px @border-gray solid;
    i{
      border-right-color: @border-gray;
      margin: -4px 0 0 -5px;
    }
  }

  &:hover .gh-count{
    u{
        border-right-color: @btn-secondary-bg--active;
    }
    i{
      border-right-color: @btn-secondary-text-color--active;
    }
  }
}

/* Share Buttons */

.shariff ul{
  text-align: center;
  margin: 3rem 0;
  .shariff-button.btn{
      padding: 0;
      border: 0;
      background: none!important;
      height: auto;

    a{
      padding: 7px 20px;
      background: @shariff-button-bg;
      color: @shariff-button-color;
      border: @shariff-button-border;
      text-decoration: none;
      &:hover{
        background: @shariff-button-bg--hover;
        color: @shariff-button-color--hover;
        border-color: @border-gray; 
      }
    }
    .share_text{
      padding-left: 4px;
    }
  }
}


/* Input Fields */

input[type=text],
input[type=password], 
input[type=email], 
input[type=url], 
input[type=time], 
input[type=date], 
input[type=datetime-local], 
input[type=tel], 
input[type=number], 
input[type=search-md], 
.form-group input[type=search],
input[type=search].csSearch__input,
textarea.md-textarea,
.select-wrapper input.select-dropdown,
.csSelected{
  font: @input-font;
  height: @input-height;
  padding: 0.5rem 0.5rem 0.5rem 0.6rem;
  color: @input-color;
  background: transparent;
  z-index: 99;
  position: relative;
  border: @input-border;
  border-radius: @input-border-radius;
  

  + label{
      color: @input-color;
      font: @input-label-font;
      .opacity(1);

      &.active {
        font: ~"300 0.875rem/1.5" @font-main;
        background: #fff;
        padding: 0 5px 0 0;
        left: 5px;
        .transform(translateY(0%));
        .opacity(0);
      }
  }

  &:focus:not([readonly]){
    border: @input-border;
    border-bottom: 1px solid @input-focus-border-color;
    .box-shadow(0 1px 0 0 @input-focus-border-color);
    + label{
      color: @input-color;
    }
  }
}



input[type=search].csBookmark__search-folder{
  font: @input-font;
  height: @input-height;
  padding-left: 22px;
  border: none;
  border-bottom: 1px solid @input-focus-border-color;

  &:focus:not([readonly]){
    border-bottom: 1px solid @input-focus-border-color;
    .box-shadow(0 1px 0 0 @input-focus-border-color);
  }

}


/* Search Input Focus*/

.csQuicksearch input[type=search]:focus:not([readonly]),
input[type=search]:focus:not([readonly]){
  border-bottom: 1px solid @input-focus-border-color;
  .box-shadow(0 1px 0 0 @input-focus-border-color);
}


/* Textarea */

textarea.md-textarea{
  height: auto;
}

.md-form label{
    top: 0.5rem;
    left: 0.5rem;
}

    
/* Select Fields */

.select-wrapper {
  span.caret,
  .select-wrapper span.caret{
    top: 13px;
    right: 0.5rem;
    color: @input-caret-color;
    z-index: 99;
  }
  ul{
    top: 39px!important;
  }
}

/* Dropdown */

.dropdown-item.active{
  background: #f5f5f5;
  &:focus,
  &:hover{
    background: #f5f5f5;
  }
}

/* Checkbox + Radio */


[type="checkbox"]:checked + label:before{
    border-right-color: @checkbox-radio-color;
    border-bottom-color: @checkbox-radio-color;
}

[type="radio"].with-gap:checked + label{
  &::before{
    border-color: @checkbox-radio-color;
  }
  &::after{
    background: @checkbox-radio-color;
    border-color: @checkbox-radio-color;
  }
}


/* Checkbox + Radio Labels */

input[type="checkbox"] + label,
input[type="checkbox"] + label p{
  font: @checkbox-label-font;
  color: @checkbox-radio-label-color;
}

input[type="radio"] + label,
input[type="radio"] + label p{
  font: @radio-label-font;
  color: @checkbox-radio-label-color;
}
