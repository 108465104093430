/* csTabs.less */

@tabs-font: ~"400 1rem/1rem" @theme-font;
@tabs-font-active: ~"600 1rem/1rem" @theme-font;
@tabs-bg: transparent;
@tabs-bg-active: rgba(158, 158, 158, 0.2);
@tabs-color: #000;
@tabs-border-color: transparent;
@tabs-border-color-active: rgba(158, 158, 158, 0.2);

.csTabs {
    .nav-tabs {
        background: @tabs-bg;
        border: 1px @border-gray solid;
        border-bottom: 0;
        z-index: 1;
        padding: 0;
        .box-shadow(none);

        .nav-link {
            padding: 1rem;
            border-bottom: 2px @tabs-border-color solid;

            &:hover {
                border-bottom: 2px @tabs-border-color solid;
            }
        }
    }

    .nav-item {
        color: @tabs-color;
        font: @tabs-font;

        a {
            color: inherit;

            &.active {
                color: @tabs-color;
                font: @tabs-font-active;
                background: @tabs-bg-active;
                border-bottom-color: @tabs-border-color-active;
                &:hover{
                    background: @tabs-bg-active;
                }
                &:focus{
                    background: @tabs-bg-active;
                }
            }
        }
    }

}

.csTabs .nav-tabs+.tab-content {
    // border: 1px @tabs-border-color solid;
    margin-top: 20px;
    background: @tabs-bg;
}

/* Tabs inside Article Content */

.csArticle__content .csTabs .nav-tabs+.tab-content {
    border: 1px @border-gray solid;
    margin-top: 20px;
    background: @tabs-bg;
}

.csArticle__content .csTabs__head {
    display: none;
}


.csArticle__content .csTabs .nav-item {
        color: @tabs-color;
        font: @tabs-font;

        a {
            color: inherit;

            &.active {
                color: @tabs-color;
                font: @tabs-font-active;
                border-bottom-color: rgba(158, 158, 158, 0.2);
                background: rgba(158, 158, 158, 0.2);
            }
        }
    }



.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-item.open .nav-link,
.nav-tabs .nav-item.open .nav-link:focus,
.nav-tabs .nav-item.open .nav-link:hover {
    color: @tabs-color;
    background: @tabs-bg;
}


.tab-content h3.h3-responsive {
        font: 200 ~"1.625rem/1.2" @font-main;

        + ul {
            margin-top: 1rem;
        }
}

.tab-content .csDefault-list li {
    line-height: 38px;

    p {
        margin: .4rem 0;

    }

    figure {
        margin: 0;

        img {
            border: 1px @border-gray solid;
        }

    }
}

.tab-content {
    p {
        font-size: 1.125em;
    }
    .csDefault-list {
        padding: 0;
        li {
            list-style: none;
        }
    }

    .group:first-of-type {
        h3 {
            margin-top: 0;
            padding-top: 0;
        }
    }
    .csWidget-wrapper + .csWidget-wrapper {
        margin-top: 30px;
    }
}

.csMedia__content {
    margin-top: 40px;
}


.col-xs-12:not(.csArticle__content) .csTabs__wrapper:not(.csMedia__content) {
    margin: 0 ~"calc(((100vw - 100%) /2 ) * -1)";
    background: #f2f2f2;
    margin-bottom: 40px;

    .csTabs {
        max-width: 1200px;
        margin: 0 auto;
        padding: 80px 20px;

        .nav-tabs {
            border: none;
            border-bottom: 1px @border-gray solid;
            background: none;
            .flex-display();
            .justify-content(center);
        }
    }
}

.csTabs__wrapper .csTabs__head {
    text-align: center;

    .csTabs__heading {
        padding: 60px 0 20px 0;
    }

    + .csTabs {
        padding-top: 0px!important;
    }
}



/* Typo inside Tab-Content */

.csTabs .csSection__content {
    h3 {
        font-size: 1.375rem;
        font-weight: 400;
    }
    ul li {
        font-size: 1rem;
    }
    p {
        font-size: 1rem;
    }
}
