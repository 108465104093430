/* media meta data list */
.card-block {
  .csWidget-list {
    li {
      padding-left: 20px;
      margin: 0 0 .5rem 0;
      display: block;
      position: relative;
      &.csLabel-desc-heading{
        margin-left: -20px;
        margin-top: 0.5rem;
        .cs-id {
            font-weight: 400;
        }
        .csLabel-header {
          font-weight: 400;
          font-size: 1.1rem;
        }
      }
      .csWidget-icon, i {
        position: absolute;
        left: 0px;
      }
      i {
        position: absolute;
        left: 0px;
        top: 3px;
      }
    }
  }
}
/* media meta data list */

/* ==================  overWrite start  ============================*/
span[selected] {
  background: @uiThemeColor !important;
  color: @white !important;
}

span.caret[selected] {
  background: none !important;
  color: @uiThemeColor !important;
}

.selected-item-wraper {
  border: 1px solid @lightGrey;
  padding: 6px 6px 2px 6px;
  .round-borders(4px);
  background: @lightGrey;
  .selected-child-item {
    margin:0px 4px 4px 0px;
    padding: 3px 6px;
    border-radius: 4px;
    background: @white;
    color: @darkGrey;
    display: inline-block;

  }
}

.csToolbar-option{
  .btn{
    width: 48px;
  }
}

.csNo-margin__select{
  input{
    &.select-dropdown{
      margin-bottom:0;
    }
  }
}


/* csProdBasket */
.csProdBasket {
  .csWidget__box {
    border-bottom: 1px @border-gray solid;
    margin: 0 0 1rem;
  }
  .price {
    color: #333;
    margin-top: 5px;
    font: 600 ~"1rem/1rem" @font-main;
    float: left;

    &.price--retail {

        &.hasOffer {
          text-decoration: line-through;
          color: #a2a2a2;
          font: 400 ~"0.875rem/1rem" @font-main;
        }
    }
    &.price--offer {

    }
  }
}

/* csMediaBasket*/
.csMediaBasket {
  .csWidget__list {
    border-top: 1px #e5e5e5 solid;
    padding: 1rem 0 0 0;
  }
}

/* csProdBasket__list */

.csProdBasket .csProdBasket__list{
  span.text-muted, p{
    font: 300 0.85rem/1.2 @font-main;
  }
}
