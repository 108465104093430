/* Slider */

/* NG */

/* Arrow Controls */

@slider-ctrl-bg: rgba(255, 255, 255, 0.54);
@slider-ctrl-arrow-color: #333;

@slider-ctrl-bg--hover: rgba(255, 255, 255, 0.49);
@slider-ctrl-arrow-color--hover: #333;

/* Circle Indicators */

@slider-indicator-color: #ccc;
@slider-indicator-color--active: #333;


/* Tab Indicators */

@slider-tab-font: ~"500 1rem/1.5rem" @font-main;
@slider-tab-border: none;

@slider-tab-bg: #fff;
@slider-tab-color: #333;

@slider-tab-bg--active: #f5f5f5;
@slider-tab-color--active: #000;

@slider-tab-bg--hover: #f5f5f5;
@slider-tab-color--hover: #000;




/* Carousel Controls */

.carousel .carousel-control{
    text-shadow: none;
    .icon-prev,
    .icon-next{
        background: @slider-ctrl-bg;
        color: @slider-ctrl-arrow-color;
        &:before{
            font-size: 20px;
            line-height: 40px;
            vertical-align: top;
        }
        &:hover{
            background: @slider-ctrl-bg--hover;
            color: @slider-ctrl-arrow-color--hover;
        }
    }
    .icon-prev:before{
        content: '\f053';
        font-family: @fontawesome;
    }
    .icon-next:before{
        content: '\f054';
        font-family: @fontawesome;

    }

}

/* Detail page slider*/

.cs-slider .carousel-control{
    width: 10%;
}

.csSliderLayoutPreview .carousel-control{
    .icon-prev,
    .icon-next{
        &:before{
            font-size: 24px;
        }
    }
    
}

/* Teaser slider */

.csCarousel-fullpage {

    .carousel-control{

        &.left{
            left: -17px;
        }
        &.right{
            right: -17px;
        }
    }


    .carousel-indicators{
        bottom: 0px;

        li{
            border: 0;
            background: @slider-indicator-color;
            width: 10px;
            height: 10px;
            margin: 1px 2px 1px 2px;

            &.active{
                background: @slider-indicator-color--active;
                width: 11px;
                height: 11px;
            }
        }
    }
}

/* Tab Slider */

.carousel .navbar{
    .navbar-nav{
        border: @slider-tab-border;
        background: @slider-tab-bg;
        .nav-item .nav-link{
            font: @slider-tab-font;
            color: @slider-tab-color;
            &:hover{
                background: @slider-tab-bg--hover;
                color: @slider-tab-color--hover;
            }
        }
        .nav-item.active .nav-link{
            background: @slider-tab-bg--active!important;
            color: @slider-tab-color--active;
        }
    }
}
