
.csWidget-wrapper {
  margin-bottom: 30px; /* widget wraper */
}

.csWidget__list{
  max-height: 200px;
  overflow-y: auto;
  li{
    &.active{
      label{
        .csWidget__label{
          color: @blue;
        }
      }
    }
  }
}
/* ==================  overWrite start  ============================*/

/* Custom Iframe  Interactive **/
.ratio {
  display: none;
}

.csiframe_wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto 20px auto;
  background: #CCC;
  clear: both;
  .csiframe {
    position: relative;
    .ratio {
      display: block;
      width: 100%;
      height: auto;
    }
    iframe.inContentIFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
/* Custom Iframe  Interactive **/





/* NG */



@teaser-headline-font: ~"400 1.125rem/1.2" @font-main;
@teaser-headline-font--large: ~"400 1.125rem/1.2" @font-main;
@teaser-headline-color: #424242;
@teaser-headline-hover-color: #373a3c;

@teaser-text-font: ~"300 1rem/1.4" @font-main;
@teaser-text-font--large: ~"300 1rem/1.5" @font-main;
@teaser-text-color: #373a3c;


@teaser-meta-font: ~"300 0.78rem/1.4" @font-main;
@teaser-meta-color: #373a3c;
@teaser-meta-hover-color: #373a3c;

@teaser-text-align: left;


/* NG */

.csTeaser {
  text-align: @teaser-text-align;

  &.csTeaser--list.card-block{
    padding: 0;
  }
  &.card,
  .card{
    border: none;
  }
  .csCard__list{
    text-align: left;
  }


  /* Headline */
  .card-title {
    font: @teaser-headline-font;
    color: @teaser-headline-color;
    margin-top: 0;
    a {
      color: inherit;
      text-decoration: none;
      &:hover{
          color: @teaser-headline-hover-color;
        }
    }
  }

  /* Text */
  .card-text {
      font: @teaser-text-font;
      color: @teaser-text-color;
      b{
        font-weight: 600;
      }
  }

  /* Meta */
  .csCard__ctrl {
      font:@teaser-meta-font;
      color: @teaser-meta-color;
      a{
        color: @teaser-meta-color;
        &:hover{
          color: @teaser-meta-hover-color;
        }
      }
  }

  .card-block{
    padding: 1.25rem 0;
  }

  /* User avatar */

  .rounded-circle{
    border: 1px #e5e5e5 solid;
  }


}

/* Large */
.csTeaser.csTeaser--large .card-title {
  font: @teaser-headline-font--large;
}

/* Monster */

.csTeaser.csTeaser--monster .card-block{
  text-align: center;
}


/* Stage-row*/

.csTeaser--stage-row .card{
  text-align: center;
}

/* Large Large */

.csTeaser.csTeaser--large-large .card-block{
  text-align: center;
}

/* Article Tiles */

.csTeaser.csTeaser--article-tiles .card-block{
  text-align: center;
}


/* Product Family */

.csTeaser.csTeaser--prod-fam .csGrid--flex .csSpacer{
  text-align: center;
}

/* Modal */

.csTeaser .modal .card-block{
  padding: 1.25rem 0.75rem;
}
