/* csTeaser--stage */

.csTeaser.csTeaser--stage {

    article {
        position: relative;
        padding: 80px 0;

        &:before {
            content: '';
            display: block;
            padding-top: 54%;
        }
    }

    picture {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        height: 100%;
        overflow: hidden;
        z-index: 2;
        margin: 0 ~"calc(((100vw - 100%) /2) * -1)";
    }

    .csTeaser__bg-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        height: 100%;
        overflow: hidden;
        z-index: 2;
        margin: 0 ~"calc(((100vw - 100%) /2) * -1)";

        left: -100px;
        right: -100px;
    }

    .card-block {
        .justify-content(center);
        .flex-direction(column);
        .flex-display;
        .align-items(center);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        margin: 0 auto;
        color: #fff;
        padding: 0 30px;
        height: 100%;
        text-align: center;
        min-width: 300px;
    }


    h1 {
        color: #fff;
        font: normal 200 ~"2.625rem/1.2"@font-main;
        margin: 0 0 .5rem;

        a {
            color: inherit;

            &:hover {
                color: inherit;
            }
        }
    }

    p {
        font: normal 400 ~"1.250rem/1.2"@font-main;
        color: #fff;
    }

    .btn-cta {
        background: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px #fff solid;
        border-top: 1px #fff solid;
        font-size: 1rem;
        letter-spacing: 0.05em;
        padding: 10px 15px;
        border-radius: 0 !important;
        min-width: 300px;

        &:hover,
        &:focus {
            background: #fff !important;
            color: #000 !important;
        }
    }
}


@media screen and (min-width: 768px) {
    .csTeaser.csTeaser--stage article:before {
        content: '';
        display: block;
        padding-top: 43%;
    }
}

@media screen and (min-width: 1200px) {
    .csTeaser.csTeaser--stage article {
        min-height: 700px;
    }
}


@media screen and (min-width: 768px) {
    .csTeaser.csTeaser--stage .csTeaser__bg-video {
        left: 0;
        right: 0;
    }
}
