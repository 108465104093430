  .screen{
    display:block;
  }
  .print{
    display:none;
  }
  header .navbar-brand .screen{
    display:block;
  }
  header .navbar-brand .print{
    display:none;
    max-width: 130px;
    height: auto;
  }

  @media print {
    *,
    *:before,
    *:after {
      color: #000 !important;
      text-shadow: none !important;
      .box-shadow(none) !important;
      -webkit-print-color-adjust: exact !important;
    }
    a,
    a:visited {
      text-decoration: none !important;

    }
    a:not([href]):not([tabindex]){
      content:"";
    }
    a[href]:after {
      content: none !important;
    }
    a[href]:after {
      content: "";
    }
    abbr[title]:after {
      content: " (" attr(title) ")";
    }
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
      content: "" !important;
    }
    a:link:after,
    a:visited:after { content:""; }
    pre,
    blockquote {
      border: 1px solid #999;

      page-break-inside: avoid;
    }
    .screen{
      display:none !important;
    }
    .print{
      display:block !important;
    }

    .noprint,
    div.alert, .group-media,
    .btn, form, ul.links.list-inline,
    ul.action-links,.page-footer .container, .mdb-lightbox:before,
    .carousel-control, .socialCommunity, .csComments, .cs_community,
    .nav, .navbar-mobile,
    .breadcrumb, footer, .page-footer{
      display:none !important;
    }
    aside{
      .cs_download, .cs_additional-article,
      .cs_relatedarticle, .cs_targetgroup,
      .csRating, .cs_likedislike, .csBookmark, .cs_geolocation, .bookmark{
        display: none;
      }
      .csWidget-wrapper {
        width: 100%;
        margin: 0 0 30px 0;
        .card{
          border: 1px solid #555;
        }
      }
    }
    .navbar {
        display: block;
        padding: 0rem;
        background-color: @white !important;
        .mobile-menu-btn, .head-social{
          display: none;
        }
        .navbar-desktop{
          display: block;
          .navbar-brand {
            display: block;
            img{
              display: none;
            }
            .print {
              display: block !important;
            }
          }
        }
      }

    .navbar-fixed-top{
      position: relative;
    }

    html ,
    body ,
    .main-wrapper
    {
      overflow: visible !important;
      width: 100%;
      max-width: none!important;
      height: 100%!important;
      max-height: none!important;
    }
    p{
      font-size:10pt!important;
      line-height:normal;
    }
    h1{
      font-size: 30px;
    }
    h1.title{
      font-size: 34px;
    }
    h2{
      font-size: 26px;
    }
    h2.subtitle, h3{
      font-size: 24px;
    }
    h3.suptitle, h4{
      font-size: 20px;
    }
    h5{
      font-size: 16px;
    }
    h1, h2, h3, h4, h5{
      line-height:normal;
    }
    .bgPrint(){
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      print-color-adjust: exact;
    }
    .alert, .message_error {
      background-color:@white !important;
      color:@black !important;
      border: 1px solid #555;
      display: block !important;
      .round-borders(0px);
      font-size: 1rem;
      line-height: 1.5rem;
    }
    main {
      overflow: hidden!important;
      max-width: none!important;
      height: auto!important;
      padding-top: 20px;
    }

    p, a, small, sup, sub, strong, b, i, em, h1, h2, h3, h4, h5
    {
      line-height: normal!important;
    }
    
    .page-footer ,
    .footer-copyright {
      max-width: none!important;
      display: block;
      text-align: left !important;
      padding-right: .6rem;
      padding-left: .6rem;
    }
    .page-footer, .footer-copyright{
      background: transparent !important;
    }
    .carousel-thumbnails {
      .carousel-indicators{
        li{
          .fa-play{
            &:before{
              color: @white !important;
            }
          }
        } 
      } 
    }
    
    aside, .col-md-4 {
      width: 100%;
      margin-bottom: 30px;
      padding: 0 30px;
        .testimonial-card{
          .avatar img{
            border: 1px solid #eceeef !important;
          }
      }
    }
    .video-wrapper .videoplayer .flowplayer{
      border: 1px @border-gray solid;
      .bgPrint;
    }
    .is-splash.flowplayer .fp-ui, .is-paused.flowplayer .fp-ui{
      .bgPrint;
    }

    table{
      thead{
        display: table-row-group !important;
      }
      col{
        border: 0px !important;
      }
      th, td, td.style-1, th.style-1,
      td.style-2, th.style-2, td.style-3,
      th.style-3, col.style-1, col.style-2,
      col.style-3{
        border-color: #555 !important;
        background-color: transparent !important;
        .bgPrint;
      }
    }

    ul, ol, ol.default{
      li{
        font-size: 10pt;
        line-height: normal;
      }
    }
    .container {
      width: 100%;
      margin: 0px;
      max-width: 100%;
    }
    * {
      color: #000;
      .box-shadow(none);
      .text-shadow(none);
    } 
  }