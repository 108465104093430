/* csProductMedia.less */

.csProduct--media {
    
    /* Item */
    .carousel-item {
        height: 260px;

        img.img-portrait,
        img.img-square,
        img.img-landscape {
            margin: auto;
        }
        img.img-landscape {
            width: 74%!important;
        }
        img.img-square {
            width: auto;
            max-height: 460px;
        }
        img.img-portrait {
            max-height: 460px;
        }
        img {
            margin: auto;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            border-radius: 0;
            transform: translateY(-50%);
        }
       .audioplayer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 260px;
            width: 80%;
            margin: auto;
        }
        .videoplayer {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        video {
            border-radius: 0;
        }
    }

    /* Controls */
      .carousel {
        .carousel-control {
            background: none;
            .flex-display;
            .flex-direction(column);
            .justify-content(center);
            font-size: 2rem;
            width: 5%; 
            height: 50%;
            top: 25%;
        }
    }

    /* Thumbs */
    .carousel-indicators {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        margin: 20px 0 0 0;
        border-top: 1px @border-gray solid;
        padding-top: 20px;
        width: 100%;
        text-align: left;


        li {
            width: 80px;
            height: 80px;
            border-radius: 0;
            border: 1px transparent solid;
            margin: 5px;
            position: relative;

            img {
                margin: auto;
                position: absolute;
                border-radius: 0;
            }

            .img-landscape {
                top: 50%;
                transform: translateY(-50%);
            }
            
            .img-portrait {
                left: 0;
                right: 0;
            }

            &.active {
                border-color: @border-gray;
            }

            i {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                margin: 0;
                padding: 0;
                text-indent: 0;
                line-height: 80px;
                font-size: 28px;
                text-align: center;
                color: @text-color-light;
            }

            svg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 999;
                margin: auto;
                width: 75%;
            }
        }
    }
}

.csCarousel__wrapper {
    position: relative;
}

@media screen and (min-width: 420px) and (max-width: 576px){
   .csProduct--media .carousel-item img.img-landscape {
        width: 55%!important;
   }
}
@media screen and (min-width: 577px) and (max-width: 768px){
   .csProduct--media .carousel-item img.img-landscape {
        width: 52%!important;
   }
}
@media screen and (min-width: 768px){
   .csProduct--media .carousel-item img.img-landscape {
        width: 62%!important;
   }
}


@media screen and (min-width: 992px){
    .csProduct--media .carousel-item img.img-landscape {
        width: 74%!important;
   }
    .csProduct--media .carousel-item {
        height: 420px;
    }
    .csProduct--media .carousel-item .audioplayer {
        height: 420px;
    }
}

@media screen and (min-width: 1200px){
   .csProduct--media .carousel-item img.img-landscape {
        width: 68%!important;
   }
    .csProduct--media .carousel-item {
        height: 460px;
    }
    .csProduct--media .carousel-item .audioplayer {
        height: 460px;
    }
}
