/* csLogin.less */

.csLogin,
.csRegister,
.csRecoverPw,
.csRegisterConfirm {

  .card {
    border: 1px @border-gray solid;
  }

}


/* Remote Login */

.user-login .btn,
.user-login .d-inline-block {
    width: 100%;
}

@media screen and (min-width: 480px) {
  .user-login .btn,
  .user-login .d-inline-block {
      width: auto;
  }
}
