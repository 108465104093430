.csIpad__overlay-scroll{
  cursor: pointer;
}

/* ======================== Overwrite end ===================== */

/* ---- modal Start ---- */
.modal-dialog {
  .modal-content {
    width: 100%;
  }

  .modal-body {
    max-height: 550px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /*MAKES OVERFLOWN OBJECTS HAVE INERTIA SCROLLING*/
    -webkit-transform: translateZ(0px); /*HELPS THE ABOVE WORK IN IOS5*/
    word-break: break-all;
    .flowplayer{
      .fp-player{
        overflow: hidden;
      }
    }
    .carousel-fade {
      .carousel-inner {
        .carousel-item {
          height: auto;
        }
      }
    }
    .csMedia-left {
      margin: 0px 20px 0px 0px;
      .csFigCaption{
        padding:0;
        p{
          margin-bottom:0;
        }
      }
    }
    .carousel-thumbnails {
      margin-bottom: 96px;
    }
  }
  .csVideo-modal {
    .media-right,
    .media-left {
      width: 100%;
    }
  }
}
/* ---- modal overide End ---- */

/* ---- Lightbox Start ---- */
.mdb-lightbox {
  position: relative;
  &:before {
    background: none;
    display: inline-block;
    font: normal normal normal 20px/1 @fontawesome !important;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: @white;
    text-shadow: 0 0 1px @black;
    content: '\f065';
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.csRating, .cs_likedislike{
  .modal{
    .modal-content{
      .modal-body{
        .csPreloader{
          height:95%;
        }
      }
    }
  }
}
.carousel-fade{
  .carousel-inner{
    > .carousel-item{
      &.active{
        perspective: none;
        position:static;
        -webkit-transform: none;
        transform: none;
      }
    }
    .carousel-caption{
      z-index: 2;
    }
  }
}
/* ---- Lightbox End ---- */


body.modal-open .navbar-fixed-top {
  z-index: 99;
}

/* for date picker in share link*/
.modal{
  -webkit-overflow-scrolling: touch;
  &.csShalink-modal-wraper{
    .modal-dialog{
      .transform(initial);
      .transition(initial);

      .modal-body{
        .transform(initial);
      }

    }
  }
}


.modal-footer {
  padding: 1.25rem;
}

.bg-gray {
  background: #f9f9f9;
}



/* NG */

/* Game Modal */

.modal-content.csInteractive-modal .modal-body{
  min-height: 400px;
}
