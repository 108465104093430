/* csBookmark */
.csBookmark {

  .csBookmark__search-folder {
    margin: 0 15px 22px 0;
    padding-left: 22px;
  }

  .card-block{
    text-align: left!important;
  }
  
  .csBookmark__search-icon {
    position: absolute;
    left: 2px;
    font-size: 1rem;
    color: @grey;
    top: 14px;
  }

  .input-group{
    ::-webkit-input-placeholder {padding-left: 0; }
    :-moz-placeholder { padding-left: 0;}
    ::-moz-placeholder { padding-left: 0;}
    :-ms-input-placeholder { padding-left: 0;}
  }
}
