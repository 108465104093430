/* csSocialLinks */

.csSocialLinks {
    ul {
        .justify-content(center);
        .flex-display;
        margin: 0;

        li {
        	color: @theme-footer-link-color;
        	font-size: 20px;

        	a {
        		color: inherit;
        	}
        }
    }
}