/* csSection.less */

.csSection {}

.csSection.csSection--outro {
    position: relative;
    padding: 40px;

    .csSection__bg-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
        height: 100%;
        overflow: hidden;
        z-index: 2;
        margin: 0 ~"calc(((100vw - 100%) /2) * -1)";
        left: -160px;
        right: -160px;
    }

    .csSection__content.overlay {
        position: relative;
        z-index: 9;
        text-align: center;
        background: rgba(255, 255, 255, 0.75);
        padding: 40px;
        right: 0%;
        left: 0%;
        margin: auto;
        width: 100%;
        max-width: 420px;
    }


    .csSection__content {
        h3 {
            margin-top: 10px;
            font: normal 200 ~"2.625rem/1.2" @font-main;
            margin: 0 0 .75rem;
            color: #000;
        }

        p {
            font: normal 400 ~"1.25rem/1.2" @font-main;
            color: #000;
        }

        .btn.btn-cta{
            background: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px #000 solid;
            border-top: 1px #000 solid;
            font-size: 1rem;
            letter-spacing: 0.05em;
            padding: 10px 15px;
            border-radius: 0 !important;
            min-width: 300px;
            color: #000!important;
            &:hover {
                color: #fff!important;
            }
        }
    }

}

@media screen and (min-width: 768px) {
    .csSection.csSection--outro {
        padding: 80px;
    }
}


.csSection.gray {
    margin: 0 ~"calc(((100vw - 100%) /2 ) * -1)";
    background-color: #f2f2f2;
    padding: 80px 0;
    margin-bottom: 80px;
}

.csSection__content {
    margin: 0 auto;
}

@media screen and (min-width: 768px){
   .csSection__content {
        max-width: 690px;
    }
}

@media screen and (min-width: 992px){
   .csSection__content {
        max-width: 930px;
    }
}

@media screen and (min-width: 1200px){
   .csSection__content {
        max-width: 1110px;
    }
}

