
/* csDropdown  */
@dropdown-link-font:          ~"300 1rem/1.2rem" @theme-font;
@dropdown-link-color:         @font-base-color;
@dropdown-link-color--hover:  @font-base-color;
  .dropdown {
      .dropdown-menu {
          padding: 0.5rem 0.7rem 0.5rem 0.5rem;

          .dropdown-item {
              padding: 0.5rem;
              color: @dropdown-link-color;
              font: @dropdown-link-font;

              &:hover {
                &:extend(.csEle__boxshadow-none);
                color: @dropdown-link-color--hover!important;
              }
          }
      }
  }
