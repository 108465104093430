/* csArticleMeta */

.csArticleMeta {

        li .fal,
        li .fas,
        li .far,
        li .fab, {
            font-size: 14px;
            width: 18px; 
        }

    .authors {
        border-bottom: 1px @border-gray solid;

        h4 {
            font: ~"300 1rem/1.2" @font-main;
            margin: 0;
        }

        div {
            .flex-display;
            .align-items(center);
            text-align: center;
            margin: 0 0 1rem 0;
        }

        a {
            display: inline-block;
        }



        img {
            max-width: 46px;
            border: 1px @border-gray solid;
            display: block;
            margin: 0 1rem 0 0;
        }

        .solo {
            .flex-direction(column);

            img {
                margin: 0 0 1rem 0;
                max-width: 100px;
            }

        }
    }
}
