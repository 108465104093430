/* Fontface NG */

/* Muli Extra Light */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-ExtraLight.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
}
/* Muli Extra Light Italic*/
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-ExtraLightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
}
/* Muli Light */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
}
/* Muli Light Italic*/
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 200;
}
/* Muli Regular */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
}
/* Muli Regular italic */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
}
/* Muli Semi */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}
/* Muli Semi Italic */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-SemiBoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
}
/* Muli Bold */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}
/* Muli Bold Italic */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
}
/* Muli Extra Bold */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
}
/* Muli Extra Bold Italic */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-ExtraBoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 600;
}
/* Muli Black */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}
/* Muli Black Italic */
@font-face {
    font-family: 'Muli';
    src: local('☺︎'), url('../fonts/muli/Muli-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
}
