/**
  * csRating
  */
.csRating {
    z-index: 1;

    .csWidget__list {
        padding: 15px 0 0 0;
        border-top: 1px @border-gray solid;

        .username {
            width: auto;
        }

        .stars {
            position: absolute;
            text-align: right;
            right: 0;
            top: 11px;
            width: 70px;

            span {
                color: #B7B7B7;
                float: left;
                width: 13px;
                margin: 0 1px 0 0;

                &.fa-star {
                    color: #FDBA4E;
                }

                &.fa-star-o {
                    float: right;
                }
            }
        }

        .stars>.give-rating:hover:before,
        .stars>.give-rating:hover~.give-rating:before {
            content: "\f005";
            color: #F58D23 !important;
            cursor: pointer;
        }
    }
}






.csRating__fancybox {
    .csFancyboxMain {
        padding: 0px !important;

        .csRating {
            margin: 0 0 0 0;
        }
    }
}


.rating {
    direction: rtl;
    float: left;
    text-align: center;
    width: 100%;
    margin: 0 0 10px 0;

    &.rate-1 {
        >span:last-child {
            &:before {
                content: "\f005";
                color: #FDBA4E;
            }
        }
    }

    &.rate-2 {

        >span:nth-child(4),
        >span:nth-child(4)~span {
            &:before {
                content: "\f005";
                color: #FDBA4E;
            }
        }
    }

    &.rate-3 {

        >span:nth-child(3),
        >span:nth-child(3)~span {
            &:before {
                content: "\f005";
                color: #FDBA4E;
            }
        }
    }

    &.rate-4 {

        >span:nth-child(2),
        >span:nth-child(2)~span {
            &:before {
                content: "\f005";
                color: #FDBA4E;
            }
        }
    }

    &.rate-5 {
        >span {
            &:before {
                content: "\f005";
                color: #FDBA4E;
            }
        }
    }
}

.rating.lead {
  direction: ltr;
}


.rating>span {
    display: inline-block;
    position: relative;
    width: 1.1em;
    width: 18px;
    height: 20px;
    color: #B7B7B7;

    &:before {
        font-size: 20px;
    }
}


.rating__desc {
    float: left;
    width: 100%;
    margin: 0 0 15px 0;
    text-align: center;
    font-size: 16px;
    cursor: default;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;

    p {
        color: @font-gray;
        width: 100%;
        float: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 80%;
        text-indent: 10%;
    }

    .txt {
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 70%;
        line-height: 22px;
        overflow: hidden;
    }

    .rating__info {
        position: relative;
        line-height: 22px;
        display: inline-block;
        width: 20px;
        vertical-align: top;

        .info-icon {
            width: 16px;
            height: 16px;
            background: #505050;
            border: 1px #505050 solid;
            padding: 0;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            cursor: pointer;
            position: relative;
            right: 0;
            margin: 0 0 0 5px;

            &:before {
                color: #ffffff;
                font-size: 12px;
            }
        }
    }

    &.display {
        .rating__overlay {
            display: block;
        }
    }

    .bold {
        font-family: @ui-font-600;
    }
}


.rating__overlay {
    display: none;
    position: absolute;
    background: #fff;
    border: 1px #ccc solid;
    right: -42px;
    top: 32px;
    text-align: center;
    padding: 20px;
    z-index: 999;
    max-width: 80%;
    min-width: 280px;
    -webkit-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
    margin: auto;

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent @border-gray transparent;

        position: absolute;
        top: -10px;
        right: 38px;
        margin: auto;
    }

    &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #fff transparent;

        position: absolute;
        top: -8px;
        //left: 0;
        right: 38px;
        margin: auto;
    }

    .rating__result {
        width: 100%;
        float: left;
        margin: 0 0 10px 0;

        &:last-child {
            margin-bottom: 0;
        }

        .star__label {
            float: left;
            font-size: 14px;
            font-family: @ui-font-600;
            display: inline-block;
            line-height: 16px;
            padding: 2px 0 0 0;

            span {
                color: #FDBA4E;
                margin: 0 5px 0 3px;
            }
        }

        .star__result {
            font-size: 14px;
            float: left;
            font-family: @ui-font-600;
            display: block;
            line-height: 16px;
            padding: 2px 0 0 0;
        }

        .result__progress {
            float: left;
            width: 100px;
            height: 14px;
            background: #f7f7f7;
            position: relative;
            border: 1px @border-gray solid;
            margin: 3px 10px 0 10px;
            width: ~"calc(100% - 90px)";

            .progress__bar {
                position: absolute;
                left: 0;
                top: 0;
                height: 12px;
                width: 20px;
                background: #8ebde2;
                /* Old browsers */
                background: -moz-linear-gradient(left, #8ebde2 0%, #5f97c5 100%);
                /* FF3.6+ */
                background: -webkit-gradient(linear, left top, right top, color-stop(0%, #8ebde2), color-stop(100%, #5f97c5));
                /* Chrome,Safari4+ */
                background: -webkit-linear-gradient(left, #8ebde2 0%, #5f97c5 100%);
                /* Chrome10+,Safari5.1+ */
                background: -o-linear-gradient(left, #8ebde2 0%, #5f97c5 100%);
                /* Opera 11.10+ */
                background: -ms-linear-gradient(left, #8ebde2 0%, #5f97c5 100%);
                /* IE10+ */
                background: linear-gradient(to right, #8ebde2 0%, #5f97c5 100%);
                /* W3C */

                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8ebde2', endColorstr='#5f97c5', GradientType=1);
                /* IE6-9 */
            }
        }
    }
}










.csUser-list {
    .list-item {
        padding: 0.2rem 0;

        &:last-child {
            padding-bottom: 0;
        }

        .user-pic {
            margin-right: 0.4rem;
            display: inline-block;
            width: 30px;
            border: @lightGrey solid 1px;
        }

        .rating {
            margin: 5px 0;
            font-size: 0.9rem;
            direction: rtl;
        }

        .csUser-name {
            max-width: ~"calc(100% - 160px)";
            .text-overflow();
            line-height: 30px;
            width: auto;
        }
        a:hover {
          text-decoration: underline;
        }
        .gh-count__grey {
            margin-right: 0.5rem;
        }
    }
}

/* ---- Rating Progress Bar Custom color Start---- */

.cs_rating-progress-bar[value]::-webkit-progress-value {
    background-color: @grey;
    .round-borders(0px);
}

.cs_rating-progress-bar[value]::-moz-progress-bar {
    background-color: @grey;
    border-radius: 0px;
}

.cs_rating-progress-bar[value]::-ms-fill {
    background-color: @grey;
    .round-borders(0x);
}

/* ---- Rating Progress Bar Custom color End---- */

/* ==================  overWrite start  ============================*/

aside {
    .card-wrapper {
        height: 300px;
        box-shadow: none;
        perspective: 1000px;

        .back {
            .card-rating {
                .rotate-btn {
                    .fa {
                        font-size: 1rem;
                    }
                }

                .progress {
                    height: 6px;
                    margin: 8px 0 16px;
                }
            }

            .fa {
              font-size: 12px;
            }

            .d {
              height: 22px;
              font-size: 0.875em;
              font-weight: 400;
              white-space: nowrap;
            }
            .dp {
              height: 22px;
              font-size: 0.875em;
              font-weight: 500;
              white-space: nowrap;
            }
        }
    }
}

/* ---- Rating Star color Start---- */
.rating {
    color: @grey  !important;

    &.rating-active {
        li:hover {
            color: @gold;
            cursor: pointer;

            &~li {
                color: @gold;
            }
        }
    }

    &.rating-disable {
        &:hover {
            .fa {
                color: @grey;
            }
        }
    }
}

/* ---- Rating Star color Start---- */