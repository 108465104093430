/* csTypo */
@link-color: @color-key;
@link-color--hover: @color-key;
@link-deco: none;
@link-deco--hover: underline;

@text-color: #333;
@text-color-light: #a2a2a2;

@font-main: "Muli";
@color-key: #424242;

@theme-box-shadow: none;


body {
    font-family: @font-main, "Roboto", sans-serif;
}


a,
a:visited {
    color: @link-color;
    text-decoration: @link-deco;

    &:hover {
        color: @link-color--hover;
        text-decoration: @link-deco--hover;
    }
}


p {
    font: 200 1.25rem/1.4 @font-main;
    color: @text-color;
}

/* Base font size */
@media only screen and (min-width: 992px){
    html {
        font-size: 16px;
    }
}


/**
** Headlines
**/

@headline-1-font: normal 200 ~"2.625rem/1.2" @font-main;
@headline-3-font: 200 ~"1.625rem/1.2" @font-main;
@headline-4-font: 400 ~"1.500rem/1.2" @font-main;
@headline-5-font: 400 ~"1.250rem/1.2" @font-main;

h1 {
    font: @headline-1-font;
    margin: 1rem 0 2rem 0;
}

h2 {
    font: ~"200 2rem/1.2" @font-main;
    margin: 2rem 0 0.5rem 0;
}

h3 {
    font: @headline-3-font;
    margin: 2rem 0 0.5rem 0;     
}

h4 {
    font: @headline-4-font;
    margin: 2rem 0 0.5rem 0;
}

h5 {
    font: @headline-5-font;
    margin: 2rem 0 0.5rem 0;
}


/* Headlines responsive override */
@media only screen and (min-width: 1200px) {
    .h1-responsive {
        font: @headline-1-font;
    }
    .h3-responsive {
        font: @headline-3-font;
    }
    .h4-responsive {
        font: @headline-4-font;
    }
    .h5-responsive {
        font: @headline-5-font;
    }
}

@media only screen and (min-width: 992px) {
    .h1-responsive {
        font: @headline-1-font;
    }
    .h3-responsive {
        font: @headline-3-font;
    }
    .h4-responsive {
        font: @headline-4-font;
    }
    .h5-responsive {
        font: @headline-5-font;
    }
}

@media screen and (max-width: 1200px) and (min-width: 300px) {
    .h1-responsive {
        font: @headline-1-font;
    }
    .h3-responsive {
        font: @headline-3-font;
    }
    .h4-responsive {
        font: @headline-4-font;
    }
    .h5-responsive {
        font: @headline-5-font;
    }
}

.csWidget__title {
    font: ~"500 1rem/1.2" @font-main;
    margin: 0 0 1rem 0;
    padding: 0 0 1rem 0;
    border-bottom: 1px @border-gray solid;
}



/**
** List
**/

ul.csDefault-list {
    padding: 0 0 0 1.2rem;
        &:before,
        &:after {
            content: "";
            clear: both;
            display: table;
        }

    li {
        list-style-type: disc;
        float: left;
        min-width: 100%;
    }

    p {
        margin-bottom: .5rem;
    }

    .csMedia-left {
        width: 40px;
        overflow: initial;
        margin: 0 15px 0 0;
    }
}

ol.csDefault-list {
    padding: 0 0 0 1.2rem;

    li {
        list-style-type: decimal;
    }

    p {
        margin-bottom: .5rem;
    }
}

.csWidget__list {
    margin: 0;

    p {
        margin-bottom: .5rem;
        font: 300 ~"1rem/1.2" @font-main;
        
        a {
            display: block;
        }

        .fa,
        .fal,
        .far,
        .fas,
        .fab {
            width: 18px;
        }
    }
}


/**
** Tables
**/

table {
    p {
        font: inherit;
    }

    th {
        font: 500 1rem/1.2 @font-main;
    }

    td {
        text-align: center;
        font: 300 1rem/1.2 @font-main;
    }

    thead {
        th {
            text-align: center;

            &:first-of-type {
                text-align: left;
            }
        }
    }
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #f1f1f1;
}
