  // @import "mixins";

  .csHeader__smartnav-icon{
    -webkit-overflow-scrolling: touch;
  }


  body.mp-pushed {
    overflow: hidden!important;
    -webkit-overflow-scrolling: auto;
  }

  .mp-pusher {
    position: relative;
    left: 0;
    padding: 0;
    margin: 0 auto;
    height: 100%;
  }

  .mp-animate{
    #mp-header,
    .mp-pusher,
    .mp-level {
      .transition(transform, 200ms, ease-in);
      -webkit-transition: -webkit-transform 200ms ease-in;
    }
  }

  .page-footer {
    .transition(transform, 200ms, ease-in);
    -webkit-transition: -webkit-transform 200ms ease-in;
  }

  .mp-menu {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 300px;
    height: 100%;
  }

  .mp-level {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    background: #336ca6;

  }

  /* overlays for pusher and for level that gets covered */
  .mp-pusher::after ,
  .mp-level::after ,
  .mp-level::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    .opacity(0);
  }

  .mp-pusher::after ,
  .mp-level::after {
    -webkit-transition: opacity .3s, width .1s .3s, height .1s .3s;
    -moz-transition: opacity .3s, width .1s .3s, height .1s .3s;
    transition: opacity .3s, width .1s .3s, height .1s .3s;
  }

  .mp-level::after {
    z-index: -1;
  }

  .mp-pusher.mp-pushed::after ,
  .mp-level.mp-level-overlay::after {
    width: 100%;
    height: 100%;

    .transition(opcity, 300ms);

    .opacity(1);
  }

  .mp-level.mp-level-overlay {
    cursor: pointer;
  }

  .mp-level.mp-level-overlay.mp-level::before {
    width: 100%;
    height: 100%;

    opacity: 1;
    background: transparent;
  }


  /* overlap */
  .mp-overlap .mp-level.mp-level-open {
    -webkit-transform: translate3d(-40px, 0, 0);
    -moz-transform: translate3d(-40px, 0, 0);
    transform: translate3d(-40px, 0, 0);

    box-shadow: 1px 0 2px rgba(0,0,0,.2);
  }

  /* First level */
  .mp-menu > .mp-level ,
  .mp-menu > .mp-level.mp-level-open ,
  .mp-menu.mp-overlap > .mp-level ,
  .mp-menu.mp-overlap > .mp-level.mp-level-open {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    box-shadow: none;
  }

  /* cover */
  .mp-cover .mp-level.mp-level-open {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  /* content style */
  .mp-menu ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  .mp-menu h2 {
    font-size: 16px;
    font-weight: 300;

    margin: 0;
    padding: 0;

    color: rgba(0,0,0,.4);
    text-shadow: 0 0 1px rgba(0,0,0,.1);
    a {
      display: block;

      padding: 12px 20px 11px 22px;
      &.active {
        background: rgba(0,0,0,.2);
      }
    }
  }

  .mp-menu.mp-overlap h2::before {
    font-size: 75%;
    line-height: 1.8;

    position: absolute;
    top: 0;
    right: 0;

    margin-right: 8px;

    -webkit-transition: opacity .3s, -webkit-transform .1s .3s;
    -moz-transition: opacity .3s, -moz-transform .1s .3s;
    transition: opacity .3s, transform .1s .3s;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);

    .opacity(0);
  }

  .mp-menu.mp-cover h2 {
    font-weight: 700;

    text-transform: uppercase;

    color: #ccc;
  }

  .mp-overlap .mp-level.mp-level-overlay > h2::before {
    -webkit-transition: -webkit-transform .3s, opacity .3s;
    -moz-transition:    -moz-transform .3s, opacity .3s;
    transition:         transform .3s, opacity .3s;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);

    .opacity(1);
  }


  .mp-menu ul li:hover {
    > a {
      color: #fff;
      text-decoration: none;
    }
  }
  .mp-menu ul li.active {
     > .mp-level h2 a.active{
        color: #2196f3;
    }
    &:hover {
      > a {
        color: #2196f3;
      }
    }
    /* fa icons */
    >span {
      color: #2196f3;
    }
    > a {
      background: rgba(0,0,0,.2);
      color: #2196f3;
    }
  }

  .mp-menu ul li > a {
    font-size: 16px;
    line-height: 18px;

    display: block;

    padding: 17px 20px 17px 30px;

    -webkit-transition: background .3s, box-shadow .3s;
    -moz-transition: background .3s, box-shadow .3s;
    transition: background .3s, box-shadow .3s;
    text-transform: uppercase;

    color: #ccc;
    outline: none;
    box-shadow: inset 0 -1px rgba(0,0,0,.2);
    text-shadow: 0 0 1px rgba(255,255,255,.1);
  }

  .mp-menu ul li::before {
    line-height: 3.5;

    position: absolute;
    z-index: -1;
    left: 10px;

    color: rgba(0,0,0,.2);
  }

  .mp-level > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0,0,0,.2), inset 0 1px rgba(0,0,0,.2);
  }

  .mp-menu ul li a:hover{
    color: #fff;
    background: rgba(0,0,0,.2);
    box-shadow: inset 0 -1px rgba(0,0,0,0);
  }

  .mp-level > ul > li:first-child > a:hover {
    // color: #fff;
    // background: rgba(0,0,0,.2);
    // box-shadow: inset 0 -1px rgba(0,0,0,0);
  }

  .mp-menu .mp-level.mp-level-overlay > ul > li > a ,
  .mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0,0,0,0);
  }

  .mp-level > ul > li:first-child > a:hover ,
  .mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0,0,0,0), inset 0 1px rgba(0,0,0,0);
    } /* seems like Chrome 34.0.1847.131 needs the second shadow otherwise the transition breaks */


    .mp-back-icon {
      position: relative;
      right: 0;
      float: right;
      top: 21px;
      right: 20px;
      font-size: 10px!important;
      color: #CACACA;
    }


    /* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
    /* We'll show the first level only */


    .csSmartNav {
      height: 100%;
      width: 300px;
      position: fixed;
      left: 0; top: 0; 
      z-index: 99;

      -webkit-transform: translate3d(-300px, 0, 0);
      -moz-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);

      .transition(transform, 200ms, ease-in);
      -webkit-transition: -webkit-transform 200ms ease-in;

      ul li { 

        a { 
          color: #ccc;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          text-decoration: none;
        }
        & > span {
          color: #ccc;
        }

        a.mp-back {
          font-family: "Roboto", sans-serif!important;
          font-weight: 500;
          font-size: 14px!important;

          position: relative;

          display: block;

          padding: 17px 20px 14px 30px;


          .transition(all, .3s);
          text-transform: uppercase;

          color: rgba(255,255,255,.5);
          outline: none;
          background: rgba(0,0,0,.1);
          box-shadow: inset 0 1px rgba(0,0,0,.1);

          &:hover{
            text-decoration: none;
          }
        }
      }

      .mp-menu {
        overflow: hidden;

        .mp-level {
          height: 100%;

          background: #313941;

          a:active + div li {
            display: none;
          }
        }
        h2 {
          color: #fff;
        }
        .csHeader__brand {
          float: none;

          width: 300px;
          height: 60px;
          margin: 0;
          padding: 0;
          .csHeader__brand-icon {
            width: 100%;
            margin: 0;
            padding: 0;
          }
        }

        [data-level] {
         // icon
         & > ul > li >span {
          position: relative;
          float: left;
          left: 12px;
          top: 20px;
          font-size: 10px!important;
        }

        > ul > li.active:not([class^="color-"]){
          color: #fff;
        }
      }

        [data-level='1'] {
         z-index: 89;
         overflow-y: hidden;
         -webkit-overflow-scrolling: auto;

         // icon
         & > ul > li >span {
          position: relative;
          float: left;
          left: 12px;
          top: 20px;
          font-size: 10px!important;
        }

        > ul > li.active:not([class^="color-"]){
          color: #fff;
        }


      }
      [data-level='2'] {
        overflow: hidden;
        z-index: 99;
        -webkit-overflow-scrolling: auto;
        &.mp-level-open {
          overflow-y: auto;

          height: 100%;

          -webkit-overflow-scrolling: touch;
        }
        ul li a {
          padding-left: 30px;
        }
        h2 {
          font-family: "Roboto", sans-serif!important;
          font-size: 16px;
          line-height: 18px;

          padding-left: 0;

          color: inherit;
          a {
            padding: 17px 20px 17px 30px;
            text-decoration: none;
          }
        }
      }
      
      [data-level='3'] {
        overflow: hidden;
        z-index: 99;
        -webkit-overflow-scrolling: auto;
        &.mp-level-open {
          overflow-y: auto;

          height: 100%;

          -webkit-overflow-scrolling: touch;

          a.active {
             color: #2196f3;
          }
        }
      }
    }
  }

  .csSmartNav,
  .csSmartNav * {
   -webkit-tap-highlight-color: rgba(0,0,0,0);
 }

 .scroll-y {
  overflow-y: auto!important;
  overflow-x: hidden!important;
  -webkit-overflow-scrolling: touch!important; /* nice webkit native scroll */
  &.mp-level-overlay {
    overflow: hidden!important;
  }
}

#sidenav-overlay{
  z-index: 9;
}

.navbar-mobile {
  display: none!important;
}

@media screen and(max-width: 1024px){
  .navbar-fixed-top {
    .container { 
      padding: 0;
    }
    .navbar {
      .round-borders(0);
    }
  }
}
