.csTestimonial {
  padding: 20px 0 0;
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
  &.csTestimonial--pl-left {
    .video-box {
      float: left;
      margin: 5px 24px 0 0;
      width: 120px;
      display: inline-block;
      position: relative;
      padding-right: 0px;
    }

  }
  &.csTestimonial--pl-right {
    .video-box {
      margin: 5px 0 0 24px;
      float: right;
      width: 120px;
      display: inline-block;
      position: relative;
      padding-right: 0px;
    }
  }
  .img-fluid {
    max-width: none;
  }
  .csFigCaption{
    text-align: center;
    padding: 8px;
  }

  .csVideo-img {
    .round-borders(50%);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 15px;
    width: 100%;
    height: auto;

  }

  a {
    color: @black;
  }
  .csText-box {
    display: inherit;
    position: relative;
    overflow: hidden;
    .csBlockquote{
      padding: .5rem 1rem;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      border-left: .25rem solid #eceeef;
      p {
        font-size: 1rem;
        margin-bottom: .1rem;
        color: @lightGrey;
      }
      .bq-title {
        font-size: 1rem;
        font-weight: 500;
        color: @black;

      }
    }
  }
}

.csBtn_cta {
  font-size: .9rem;
  &:visited {
    color: @white;
  }

}
.csAnchor-target {
  display: block;
  position: relative;
  visibility: hidden;
  clear: both;
}
