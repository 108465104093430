@charset "UTF-8";
@font-face {
  font-family: flowplayer;
  src: url("../fonts/flowplayer.eot?#iefix") format("embedded-opentype"), url("../fonts/flowplayer.woff2") format("woff2"), url("../fonts/flowplayer.woff") format("woff"); }

.fp-icon {
  display: inline-block; }
.fp-icon:before {
  font-family: flowplayer;
  font-size: 1.7em; }

.fp-airplay:before {
  content: "\e001"; }

.fp-fullscreen:before {
  content: "\e002"; }

.fp-checked:before {
  content: "\e003"; }

.fp-embed:before {
  content: "\e004";
  color: #fff; }

.fp-chromecast:before {
  content: "\e005"; }

.fp-email:before {
  content: "\e006"; }

.fp-playbtn:before {
  content: "\e007"; }

.fp-share:before {
  content: "\e009"; }

.fp-share2:before {
  content: "\e00a"; }

.fp-twitter:before {
  content: "\e00c";
  color: #1da1f2; }

.fp-facebook:before {
  content: "\e00a";
  color: #3b5998; }

.fp-volumebtn:before {
  content: "\e00b"; }

.fp-volume-off:before {
  content: "\e00d"; }

.fp-unload:before {
  content: "\e00f"; }

.fp-prevbtn:before {
  content: "\e011"; }

.fp-nextbtn:before {
  content: "\e012"; }

.flowplayer.is-mouseover .fp-captions, .flowplayer.is-paused .fp-captions {
  bottom: 3em; }

.flowplayer.is-poster .fp-engine {
  filter: alpha(opacity=0);
  opacity: 0; }

.flowplayer.is-loading .fp-engine {
  top: -9999em; }

.flowplayer.is-loading .fp-ui .fp-header, .flowplayer.is-loading .fp-ui .fp-controls {
  filter: alpha(opacity=0);
  opacity: 0; }

.flowplayer.is-splash .fp-ui > *, .flowplayer.is-poster .fp-ui > * {
  display: none; }

.flowplayer.is-splash .fp-ui > .fp-play, .flowplayer.is-poster .fp-ui > .fp-play {
  display: block; }

.flowplayer.is-splash.is-loading .fp-ui > .fp-play, .flowplayer.is-poster.is-loading .fp-ui > .fp-play {
  display: none; }

.flowplayer.is-poster .fp-engine {
  left: -9999em;
  top: -9999em; }

.flowplayer.is-disabled .fp-color {
  background-color: #999; }

.flowplayer.has-title .fp-header {
  top: 0;
  text-align: right; }

.flowplayer.is-fullscreen {
  top: 0 !important;
  left: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  z-index: 99999 !important;
  -webkit-box-shadow: 0 !important;
  box-shadow: 0 !important;
  background-image: none !important;
  background-color: #333; }
.flowplayer.is-fullscreen .fp-player {
  background-color: #333; }
.flowplayer.is-fullscreen .fp-fullscreen:before {
  content: "\e016"; }

.flowplayer.is-fullscreen.fp-outlined .fp-fullscreen:before, .flowplayer.is-fullscreen.fp-minimal .fp-fullscreen:before {
  content: "\e216"; }

.flowplayer.is-fullscreen.fp-edgy .fp-fullscreen:before {
  content: "\e116"; }

.flowplayer.is-fullscreen.fp-edgy.fp-outlined .fp-fullscreen:before, .flowplayer.is-fullscreen.fp-edgy.fp-minimal .fp-fullscreen:before {
  content: "\e316"; }

.flowplayer.is-loading .fp-waiting svg, .flowplayer.is-loading .fp-waiting p, .flowplayer.is-seeking .fp-waiting svg, .flowplayer.is-seeking .fp-waiting p {
  filter: alpha(opacity=100);
  opacity: 1; }

.flowplayer.is-loading .fp-play, .flowplayer.is-seeking .fp-play {
  display: none !important; }

.flowplayer.is-playing {
  background-image: none !important; }
.flowplayer.is-playing .fp-playbtn:before {
  content: "\e008"; }

.flowplayer.is-playing.fp-outlined .fp-playbtn:before, .flowplayer.is-playing.fp-minimal .fp-playbtn:before {
  content: "\e208"; }

.flowplayer.is-playing.fp-edgy .fp-playbtn:before {
  content: "\e108"; }

.flowplayer.is-playing.fp-edgy.fp-outlined .fp-playbtn:before, .flowplayer.is-playing.fp-edgy.fp-minimal .fp-playbtn:before {
  content: "\e308"; }

.flowplayer.is-muted .fp-volumebtn:before {
  content: "\e00d"; }

.flowplayer.is-muted.fp-outlined .fp-volumebtn:before, .flowplayer.is-muted.fp-minimal .fp-volumebtn:before {
  content: "\e20d"; }

.flowplayer.is-muted.fp-edgy .fp-volumebtn:before {
  content: "\e10d"; }

.flowplayer.is-muted.fp-edgy.fp-outlined .fp-volumebtn:before, .flowplayer.is-muted.fp-edgy.fp-minimal .fp-volumebtn:before {
  content: "\e30d"; }

.flowplayer.is-inverted .fp-duration {
  display: none; }

.flowplayer.is-inverted .fp-remaining {
  display: block; }

.flowplayer.is-closeable .fp-header .fp-unload {
  display: block; }

.flowplayer.is-error {
  background-color: #aaa; }
.flowplayer.is-error .fp-ui {
  filter: alpha(opacity=100);
  opacity: 1; }

.flowplayer.is-live .fp-timeline {
  visibility: hidden; }

.flowplayer.is-live.is-dvr .fp-timeline {
  visibility: visible; }

.flowplayer.is-live.is-dvr.is-live-position .fp-duration {
  color: #00abcd; }

.flowplayer.is-live .fp-timestamp {
  display: none !important; }

.flowplayer.is-flash-disabled .fp-waiting {
  display: none !important; }

.flowplayer.is-flash-disabled .fp-ui {
  height: auto;
  background: none;
  filter: alpha(opacity=100);
  opacity: 1; }
.flowplayer.is-flash-disabled .fp-ui .fp-header, .flowplayer.is-flash-disabled .fp-ui .fp-controls {
  display: none; }

.flowplayer.is-flash-disabled .fp-engine {
  top: 0; }

.flowplayer.no-buffer .fp-ui .fp-controls .fp-timeline .fp-buffer {
  display: none; }

.flowplayer.no-volume .fp-volumebar {
  display: none; }

.flowplayer.fp-mute .fp-volumebtn {
  display: inline-block; }

@-webkit-keyframes pulse {
  0% {
    filter: alpha(opacity=0);
    opacity: 0; }
  100% {
    filter: alpha(opacity=100);
    opacity: 1; } }

@keyframes pulse {
  0% {
    filter: alpha(opacity=0);
    opacity: 0; }
  100% {
    filter: alpha(opacity=100);
    opacity: 1; } }

.flowplayer .fp-chromecast-engine {
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  color: #fff; }
.flowplayer .fp-chromecast-engine .fp-chromecast-engine-status {
  font-size: 150%;
  text-align: center; }
.flowplayer .fp-chromecast-engine .fp-chromecast-engine-icon {
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiIG9wYWNpdHk9Ii4xIi8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+CiAgICA8cGF0aCBkPSJNMSAxOHYzaDNjMC0xLjY2LTEuMzQtMy0zLTN6bTAtNHYyYzIuNzYgMCA1IDIuMjQgNSA1aDJjMC0zLjg3LTMuMTMtNy03LTd6bTE4LTdINXYxLjYzYzMuOTYgMS4yOCA3LjA5IDQuNDEgOC4zNyA4LjM3SDE5Vjd6TTEgMTB2MmM0Ljk3IDAgOSA0LjAzIDkgOWgyYzAtNi4wOC00LjkzLTExLTExLTExem0yMC03SDNjLTEuMSAwLTIgLjktMiAydjNoMlY1aDE4djE0aC03djJoN2MxLjEgMCAyLS45IDItMlY1YzAtMS4xLS45LTItMi0yeiIvPgo8L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiIG9wYWNpdHk9Ii4xIi8+CiAgICA8cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+CiAgICA8cGF0aCBkPSJNMSAxOHYzaDNjMC0xLjY2LTEuMzQtMy0zLTN6bTAtNHYyYzIuNzYgMCA1IDIuMjQgNSA1aDJjMC0zLjg3LTMuMTMtNy03LTd6bTE4LTdINXYxLjYzYzMuOTYgMS4yOCA3LjA5IDQuNDEgOC4zNyA4LjM3SDE5Vjd6TTEgMTB2MmM0Ljk3IDAgOSA0LjAzIDkgOWgyYzAtNi4wOC00LjkzLTExLTExLTExem0yMC03SDNjLTEuMSAwLTIgLjktMiAydjNoMlY1aDE4djE0aC03djJoN2MxLjEgMCAyLS45IDItMlY1YzAtMS4xLS45LTItMi0yeiIvPgo8L3N2Zz4=");
  -webkit-mask-size: 100% 100%;
  background-color: #fff;
  margin: 10% auto 2em;
  height: 5em;
  width: 5em; }

.flowplayer.is-chromecast {
  background-color: #333; }
.flowplayer.is-chromecast .fp-chromecast-engine {
  display: block; }
.flowplayer.is-chromecast .fp-flash-disabled {
  display: none !important; }
.flowplayer.is-chromecast .fp-engine {
  left: -9999em;
  top: -9999em;
  bottom: auto;
  right: auto; }

.flowplayer.fp-default-playlist .fp-prev, .flowplayer.fp-default-playlist .fp-next {
  position: absolute;
  top: 45%;
  filter: alpha(opacity=30);
  opacity: 0.3;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s; }
.flowplayer.fp-default-playlist .fp-prev:hover, .flowplayer.fp-default-playlist .fp-next:hover {
  filter: alpha(opacity=100);
  opacity: 1; }
.flowplayer.fp-default-playlist .fp-prev:before, .flowplayer.fp-default-playlist .fp-next:before {
  font-family: flowplayer;
  font-size: 2.8em; }

.flowplayer.fp-default-playlist .fp-prev {
  left: 0.4em; }
.flowplayer.fp-default-playlist .fp-prev:before {
  content: "\e011"; }

.flowplayer.fp-default-playlist .fp-next {
  right: 0.4em; }
.flowplayer.fp-default-playlist .fp-next:before {
  content: "\e012"; }

.flowplayer.fp-default-playlist .fp-playlist {
  position: absolute;
  right: 0.4em;
  bottom: 3em;
  width: 100%;
  text-align: center; }
.flowplayer.fp-default-playlist .fp-playlist a {
  background-color: #fff;
  height: 0.8em;
  border-radius: 50%;
  width: 0.8em;
  filter: alpha(opacity=70);
  opacity: 0.7;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s; }
.flowplayer.fp-default-playlist .fp-playlist a:hover {
  -webkit-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2); }
.flowplayer.fp-default-playlist .fp-playlist a.is-active {
  filter: alpha(opacity=100);
  opacity: 1; }

.flowplayer.fp-default-playlist.fp-edgy .fp-prev:before {
  content: "\e111"; }

.flowplayer.fp-default-playlist.fp-edgy .fp-next:before {
  content: "\e112"; }

.flowplayer.fp-default-playlist.fp-outlined .fp-prev:before, .flowplayer.fp-default-playlist.fp-minimal .fp-prev:before {
  content: "\e211"; }

.flowplayer.fp-default-playlist.fp-outlined .fp-next:before, .flowplayer.fp-default-playlist.fp-minimal .fp-next:before {
  content: "\e212"; }

.flowplayer.fp-default-playlist.fp-edgy.fp-outlined .fp-prev:before, .flowplayer.fp-default-playlist.fp-edgy.fp-minimal .fp-prev:before {
  content: "\e311"; }

.flowplayer.fp-default-playlist.fp-edgy.fp-outlined .fp-next:before, .flowplayer.fp-default-playlist.fp-edgy.fp-minimal .fp-next:before {
  content: "\e312"; }

.flowplayer.fp-custom-playlist {
  overflow: visible; }
.flowplayer.fp-custom-playlist .fp-playlist, .flowplayer.fp-custom-playlist.is-splash .fp-playlist, .flowplayer.fp-custom-playlist.is-poster .fp-playlist, .flowplayer.fp-custom-playlist.is-loading .fp-playlist {
  display: block;
  filter: alpha(opacity=100);
  opacity: 1; }

.flowplayer.fp-edgy .fp-play svg.fp-play-rounded-fill, .flowplayer.fp-edgy .fp-play svg.fp-pause-rounded-fill, .flowplayer.fp-edgy .fp-play svg.fp-play-rounded-outline, .flowplayer.fp-edgy .fp-play svg.fp-pause-rounded-outline, .flowplayer.fp-edgy .fp-play svg.fp-loading-rounded-outline, .flowplayer.fp-edgy .fp-play svg.fp-loading-rounded-fill, .flowplayer.fp-edgy .fp-pause svg.fp-play-rounded-fill, .flowplayer.fp-edgy .fp-pause svg.fp-pause-rounded-fill, .flowplayer.fp-edgy .fp-pause svg.fp-play-rounded-outline, .flowplayer.fp-edgy .fp-pause svg.fp-pause-rounded-outline, .flowplayer.fp-edgy .fp-pause svg.fp-loading-rounded-outline, .flowplayer.fp-edgy .fp-pause svg.fp-loading-rounded-fill, .flowplayer.fp-edgy .fp-waiting svg.fp-play-rounded-fill, .flowplayer.fp-edgy .fp-waiting svg.fp-pause-rounded-fill, .flowplayer.fp-edgy .fp-waiting svg.fp-play-rounded-outline, .flowplayer.fp-edgy .fp-waiting svg.fp-pause-rounded-outline, .flowplayer.fp-edgy .fp-waiting svg.fp-loading-rounded-outline, .flowplayer.fp-edgy .fp-waiting svg.fp-loading-rounded-fill {
  display: none !important; }

.flowplayer.fp-edgy .fp-play svg.fp-play-sharp-fill, .flowplayer.fp-edgy .fp-play svg.fp-pause-sharp-fill, .flowplayer.fp-edgy .fp-play svg.fp-loading-sharp-fill, .flowplayer.fp-edgy .fp-pause svg.fp-play-sharp-fill, .flowplayer.fp-edgy .fp-pause svg.fp-pause-sharp-fill, .flowplayer.fp-edgy .fp-pause svg.fp-loading-sharp-fill, .flowplayer.fp-edgy .fp-waiting svg.fp-play-sharp-fill, .flowplayer.fp-edgy .fp-waiting svg.fp-pause-sharp-fill, .flowplayer.fp-edgy .fp-waiting svg.fp-loading-sharp-fill {
  display: block; }

.flowplayer.fp-edgy.fp-outlined .fp-play svg.fp-play-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-play svg.fp-play-sharp-fill, .flowplayer.fp-edgy.fp-outlined .fp-play svg.fp-pause-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-play svg.fp-pause-sharp-fill, .flowplayer.fp-edgy.fp-outlined .fp-play svg.fp-loading-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-play svg.fp-loading-sharp-fill, .flowplayer.fp-edgy.fp-outlined .fp-pause svg.fp-play-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-pause svg.fp-play-sharp-fill, .flowplayer.fp-edgy.fp-outlined .fp-pause svg.fp-pause-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-pause svg.fp-pause-sharp-fill, .flowplayer.fp-edgy.fp-outlined .fp-pause svg.fp-loading-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-pause svg.fp-loading-sharp-fill, .flowplayer.fp-edgy.fp-outlined .fp-waiting svg.fp-play-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-waiting svg.fp-play-sharp-fill, .flowplayer.fp-edgy.fp-outlined .fp-waiting svg.fp-pause-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-waiting svg.fp-pause-sharp-fill, .flowplayer.fp-edgy.fp-outlined .fp-waiting svg.fp-loading-sharp-fill, .flowplayer.fp-edgy.fp-minimal .fp-waiting svg.fp-loading-sharp-fill {
  display: none; }

.flowplayer.fp-edgy.fp-outlined .fp-play svg.fp-play-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-play svg.fp-play-sharp-outline, .flowplayer.fp-edgy.fp-outlined .fp-play svg.fp-pause-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-play svg.fp-pause-sharp-outline, .flowplayer.fp-edgy.fp-outlined .fp-play svg.fp-loading-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-play svg.fp-loading-sharp-outline, .flowplayer.fp-edgy.fp-outlined .fp-pause svg.fp-play-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-pause svg.fp-play-sharp-outline, .flowplayer.fp-edgy.fp-outlined .fp-pause svg.fp-pause-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-pause svg.fp-pause-sharp-outline, .flowplayer.fp-edgy.fp-outlined .fp-pause svg.fp-loading-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-pause svg.fp-loading-sharp-outline, .flowplayer.fp-edgy.fp-outlined .fp-waiting svg.fp-play-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-waiting svg.fp-play-sharp-outline, .flowplayer.fp-edgy.fp-outlined .fp-waiting svg.fp-pause-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-waiting svg.fp-pause-sharp-outline, .flowplayer.fp-edgy.fp-outlined .fp-waiting svg.fp-loading-sharp-outline, .flowplayer.fp-edgy.fp-minimal .fp-waiting svg.fp-loading-sharp-outline {
  display: block; }

.flowplayer.fp-edgy, .flowplayer.fp-edgy .fp-menu, .flowplayer.fp-edgy .fp-captions p, .flowplayer.fp-edgy .fp-textarea, .flowplayer.fp-edgy .fp-bar, .flowplayer.fp-edgy .fp-bar-slider, .flowplayer.fp-edgy .fp-bar > *, .flowplayer.fp-edgy .fp-bar-slider > *, .flowplayer.fp-edgy .fp-timestamp {
  border-radius: 0; }

.flowplayer.fp-edgy .fp-airplay:before {
  content: "\e101"; }

.flowplayer.fp-edgy .fp-fullscreen:before {
  content: "\e102"; }

.flowplayer.fp-edgy .fp-checked:before {
  content: "\e103"; }

.flowplayer.fp-edgy .fp-embed:before {
  content: "\e104"; }

.flowplayer.fp-edgy .fp-chromecast:before {
  content: "\e105"; }

.flowplayer.fp-edgy .fp-email:before {
  content: "\e106"; }

.flowplayer.fp-edgy .fp-playbtn:before {
  content: "\e107"; }

.flowplayer.fp-edgy .fp-share:before {
  content: "\e109"; }

.flowplayer.fp-edgy .fp-share2:before {
  content: "\e10a"; }

.flowplayer.fp-edgy .fp-twitter:before {
  content: "\e10c"; }

.flowplayer.fp-edgy .fp-facebook:before {
  content: "\e10a"; }

.flowplayer.fp-edgy .fp-volumebtn:before {
  content: "\e10b"; }

.flowplayer.fp-edgy .fp-volume-off:before {
  content: "\e10d"; }

.flowplayer.fp-edgy .fp-unload:before {
  content: "\e10f"; }

.flowplayer.fp-edgy .fp-prevbtn:before {
  content: "\e111"; }

.flowplayer.fp-edgy .fp-nextbtn:before {
  content: "\e112"; }

.flowplayer.fp-edgy.fp-outlined .fp-airplay:before, .flowplayer.fp-edgy.fp-minimal .fp-airplay:before {
  content: "\e301"; }

.flowplayer.fp-edgy.fp-outlined .fp-fullscreen:before, .flowplayer.fp-edgy.fp-minimal .fp-fullscreen:before {
  content: "\e302"; }

.flowplayer.fp-edgy.fp-outlined .fp-checked:before, .flowplayer.fp-edgy.fp-minimal .fp-checked:before {
  content: "\e303"; }

.flowplayer.fp-edgy.fp-outlined .fp-embed:before, .flowplayer.fp-edgy.fp-minimal .fp-embed:before {
  content: "\e304"; }

.flowplayer.fp-edgy.fp-outlined .fp-chromecast:before, .flowplayer.fp-edgy.fp-minimal .fp-chromecast:before {
  content: "\e305"; }

.flowplayer.fp-edgy.fp-outlined .fp-email:before, .flowplayer.fp-edgy.fp-minimal .fp-email:before {
  content: "\e306"; }

.flowplayer.fp-edgy.fp-outlined .fp-playbtn:before, .flowplayer.fp-edgy.fp-minimal .fp-playbtn:before {
  content: "\e307"; }

.flowplayer.fp-edgy.fp-outlined .fp-share:before, .flowplayer.fp-edgy.fp-minimal .fp-share:before {
  content: "\e309"; }

.flowplayer.fp-edgy.fp-outlined .fp-share2:before, .flowplayer.fp-edgy.fp-minimal .fp-share2:before {
  content: "\e30a"; }

.flowplayer.fp-edgy.fp-outlined .fp-twitter:before, .flowplayer.fp-edgy.fp-minimal .fp-twitter:before {
  content: "\e30c"; }

.flowplayer.fp-edgy.fp-outlined .fp-facebook:before, .flowplayer.fp-edgy.fp-minimal .fp-facebook:before {
  content: "\e30a"; }

.flowplayer.fp-edgy.fp-outlined .fp-volumebtn:before, .flowplayer.fp-edgy.fp-minimal .fp-volumebtn:before {
  content: "\e30b"; }

.flowplayer.fp-edgy.fp-outlined .fp-volume-off:before, .flowplayer.fp-edgy.fp-minimal .fp-volume-off:before {
  content: "\e30d"; }

.flowplayer.fp-edgy.fp-outlined .fp-unload:before, .flowplayer.fp-edgy.fp-minimal .fp-unload:before {
  content: "\e30f"; }

.flowplayer.fp-edgy.fp-outlined .fp-prevbtn:before, .flowplayer.fp-edgy.fp-minimal .fp-prevbtn:before {
  content: "\e311"; }

.flowplayer.fp-edgy.fp-outlined .fp-nextbtn:before, .flowplayer.fp-edgy.fp-minimal .fp-nextbtn:before {
  content: "\e312"; }

.flowplayer.no-svg .fp-ui .fp-header, .flowplayer.no-svg .fp-ui .fp-controls {
  background-color: #333; }

.flowplayer.no-svg .fp-ui .fp-play.fp-visible {
  background-color: #ccc;
  position: absolute;
  padding-top: 17%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
.flowplayer.no-svg .fp-ui .fp-play.fp-visible svg {
  display: none; }
.flowplayer.no-svg .fp-ui .fp-play.fp-visible .fp-playbtn {
  display: inline; }

.flowplayer .fp-player, .flowplayer .fp-ui, .flowplayer .fp-header, .flowplayer .fp-captions, .flowplayer .fp-controls {
  position: absolute;
  width: 100%; }

.flowplayer {
  font-family: avenir, sans-serif;
  font-size: 16px;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  border-radius: .24em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  zoom: 1 !important;
  width: 100%;
  display: inline-block; }
.flowplayer .fp-hidden {
  display: none; }
.flowplayer .fp-shown {
  display: block !important; }
.flowplayer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
.flowplayer.is-ready:not(.is-poster) {
  background-color: #333; }
.flowplayer .fp-engine {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%; }
.flowplayer video.fp-engine::-webkit-media-controls {
  display: none !important; }
.flowplayer .fp-player {
  position: absolute;
  bottom: 0;
  top: 0; }
.flowplayer .fp-waiting p {
  filter: alpha(opacity=0);
  opacity: 0;
  color: #ccc;
  font-weight: bold; }
.flowplayer .fp-ui {
  height: 100%;
  top: 0;
  background-image: none;
  -webkit-transition: background-image .1s;
  transition: background-image .1s;
  text-align: center;
  cursor: pointer;
  color: #fff; }
.flowplayer .fp-ui a, .flowplayer .fp-ui strong {
  color: inherit !important;
  font-style: normal !important;
  text-decoration: none !important; }
.flowplayer .fp-ui strong {
  font-weight: bold !important; }
.flowplayer .fp-ui > * {
  -webkit-transition: opacity .1s;
  transition: opacity .1s;
  filter: alpha(opacity=0);
  opacity: 0; }
.flowplayer.fp-ui-shown .fp-ui, .flowplayer.is-paused .fp-ui, .flowplayer.is-mouseover .fp-ui, .flowplayer.is-loading .fp-ui {
  //background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.25)), color-stop(15%, transparent));
  //background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.25), transparent 15%);
  //background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent 15%); 
   }
.flowplayer.fp-ui-shown .fp-ui > *, .flowplayer.is-paused .fp-ui > *, .flowplayer.is-mouseover .fp-ui > *, .flowplayer.is-loading .fp-ui > * {
  filter: alpha(opacity=100);
  opacity: 1; }
.flowplayer .fp-header {
  top: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(transparent));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25), transparent);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), transparent);
  text-align: left;
  height: 3.6em;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  padding-left: .9em; }
.flowplayer .fp-header .fp-icon {
  margin: .9em .9em 0 0; }
.flowplayer .fp-header .fp-icon.fp-active {
  color: #ec6c4c; }
.flowplayer .fp-header .fp-fullscreen, .flowplayer .fp-header .fp-unload {
  float: right; }
.flowplayer .fp-header .fp-unload {
  display: none; }
.flowplayer .fp-help {
  display: none; }
.flowplayer .fp-message {
  background-color: rgba(0, 0, 0, 0.5);
  text-align: left;
  font-size: 1.2em;
  overflow: hidden;
  filter: alpha(opacity=0);
  opacity: 0;
  padding: .6em .9em;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s; }
.flowplayer .fp-message.fp-shown {
  filter: alpha(opacity=100);
  opacity: 1; }
.flowplayer .fp-message.fp-shown + .fp-header {
  filter: alpha(opacity=0);
  opacity: 0; }
.flowplayer .fp-textarea {
  background-color: rgba(0, 0, 0, 0.5);
  width: 80%;
  position: absolute;
  left: 10%;
  top: 15%;
  color: #fff;
  outline: 0;
  border-radius: .2em;
  border: 0;
  min-height: 60%; }
.flowplayer .fp-logo {
  position: absolute;
  bottom: 3em;
  left: 0.9em; }
.flowplayer .fp-logo img {
  width: 100%; }
.flowplayer .fp-captions {
  bottom: 1.2em;
  display: none;
  text-align: center;
  color: #fff; }
.flowplayer .fp-captions p {
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: .2em;
  font-size: 110%;
  display: inline-block;
  padding: .1em .3em;
  margin: .1em; }
.flowplayer .fp-speed-flash {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  font-size: 4em;
  color: #fff;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  filter: alpha(opacity=0) !important;
  opacity: 0 !important;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all .2s;
  transition: all .2s;
  width: 4em;
  height: 2em;
  display: none; }
.flowplayer .fp-speed-flash.fp-hilite {
  filter: alpha(opacity=100) !important;
  opacity: 1 !important;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }
.flowplayer .fp-play .fp-playbtn, .flowplayer .fp-pause .fp-playbtn, .flowplayer .fp-waiting .fp-playbtn {
  display: none; }
.flowplayer .fp-play svg, .flowplayer .fp-pause svg, .flowplayer .fp-waiting svg {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 6em;
  height: 6em;
  filter: alpha(opacity=0);
  opacity: 0;
  margin: auto;
  max-height: 30%;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all .2s;
  transition: all .2s;
  display: none; }
.flowplayer .fp-play svg.fp-play-rounded-fill, .flowplayer .fp-play svg.fp-pause-rounded-fill, .flowplayer .fp-play svg.fp-loading-rounded-fill, .flowplayer .fp-pause svg.fp-play-rounded-fill, .flowplayer .fp-pause svg.fp-pause-rounded-fill, .flowplayer .fp-pause svg.fp-loading-rounded-fill, .flowplayer .fp-waiting svg.fp-play-rounded-fill, .flowplayer .fp-waiting svg.fp-pause-rounded-fill, .flowplayer .fp-waiting svg.fp-loading-rounded-fill {
  display: block; }
.flowplayer .fp-play.fp-visible svg, .flowplayer .fp-pause.fp-visible svg, .flowplayer .fp-waiting.fp-visible svg {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  filter: alpha(opacity=100);
  opacity: 1; }
.flowplayer .fp-remaining {
  display: none; }
.flowplayer .fp-controls {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  align-items: center;
  height: 2.4em;
  bottom: 0;
  z-index: 2;
  padding-left: 0.3em;
  padding-right: 0.3em; }
.flowplayer .fp-controls > * {
  margin: 0 .6em; }
.flowplayer .fp-controls > strong {
  letter-spacing: 1px; }
.flowplayer .fp-controls img {
  width: 1.2em; }
.flowplayer .fp-bar, .flowplayer .fp-bar-slider {
  background-color: #ccc;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  cursor: col-resize;
  height: .9em;
  border-radius: 0.24em;
  -ms-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  flex: 1; }
.flowplayer .fp-bar > *, .flowplayer .fp-bar-slider > * {
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  height: 100%;
  border-radius: 0.24em; }
.flowplayer .fp-bar > *.fp-progress.animated, .flowplayer .fp-bar-slider > *.fp-progress.animated {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-property: width, height;
  transition-property: width, height; }
.flowplayer .fp-bar > *.fp-buffer, .flowplayer .fp-bar-slider > *.fp-buffer {
  -webkit-transition: width .25s linear;
  transition: width .25s linear; }
.flowplayer .fp-bar.no-animation > *, .flowplayer .no-animation.fp-bar-slider > * {
  -webkit-transition: none;
  transition: none; }
.flowplayer .fp-timeline {
  -webkit-transition: height .2s;
  transition: height .2s; }
.flowplayer .fp-cuepoint {
  background-color: #000;
  position: absolute;
  height: 100%;
  width: 2px; }
.flowplayer .fp-timestamp {
  background-color: rgba(0, 0, 0, 0.65);
  display: none;
  border-radius: .2em;
  padding: .1em .3em;
  font-size: 90%;
  bottom: 1.4em;
  height: auto; }
.flowplayer .fp-timeline:hover .fp-timestamp {
  display: inline; }
.flowplayer .fp-volume {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  height: 100%;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center; }
.flowplayer .fp-volumebtn {
  display: none; }
.flowplayer .fp-bar-slider {
  background-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: height .2s;
  transition: height .2s;
  white-space: nowrap;
  -ms-flex: 1 0 auto; }
.flowplayer .fp-bar-slider em {
  border-radius: 2px;
  display: inline-block;
  width: 5px;
  height: 100%;
  position: relative;
  vertical-align: top;
  margin-left: 3px;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }
.flowplayer .fp-bar-slider em:hover {
  -webkit-transform: scaleY(1.35);
  -ms-transform: scaleY(1.35);
  transform: scaleY(1.35);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s; }
.flowplayer .fp-bar-slider em.fp-grey {
  background-color: rgba(255, 255, 255, 0.75); }
.flowplayer.no-flex .fp-controls {
  white-space: nowrap; }
.flowplayer.no-flex .fp-controls > * {
  display: inline-block;
  vertical-align: center;
  margin: 0 .3em; }
.flowplayer.no-flex .fp-timeline {
  width: 40%; }
.flowplayer.no-flex.fp-full .fp-timeline {
  margin-bottom: .6em;
  height: .9em; }
.flowplayer.no-flex.fp-fat .fp-bar, .flowplayer.no-flex.fp-playful .fp-bar, .flowplayer.no-flex.fp-fat .fp-bar-slider, .flowplayer.no-flex.fp-playful .fp-bar-slider {
  height: .9em !important; }
.flowplayer.fp-fat .fp-bar, .flowplayer.fp-playful .fp-bar, .flowplayer.fp-fat .fp-bar-slider, .flowplayer.fp-playful .fp-bar-slider {
  height: 100%;
  border-radius: 0; }
.flowplayer.fp-fat .fp-bar > *, .flowplayer.fp-playful .fp-bar > *, .flowplayer.fp-fat .fp-bar-slider > *, .flowplayer.fp-playful .fp-bar-slider > * {
  border-radius: 0; }
.flowplayer.fp-fat .fp-timestamp, .flowplayer.fp-playful .fp-timestamp {
  bottom: 3.2em; }
.flowplayer.fp-fat .fp-bar-slider em, .flowplayer.fp-playful .fp-bar-slider em {
  -webkit-transform: scaleY(0.6);
  -ms-transform: scaleY(0.6);
  transform: scaleY(0.6);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center; }
.flowplayer.fp-fat .fp-bar-slider em:hover, .flowplayer.fp-playful .fp-bar-slider em:hover {
  -webkit-transform: scaleY(0.75);
  -ms-transform: scaleY(0.75);
  transform: scaleY(0.75); }
.flowplayer.fp-slim .fp-timeline {
  height: .2em; }
.flowplayer.fp-slim .fp-controls:hover .fp-timeline {
  height: .9em; }
.flowplayer.fp-slim .fp-cue {
  width: 4px; }
.flowplayer.fp-slim .fp-bar-slider {
  height: .2em; }
.flowplayer.fp-slim .fp-controls:hover .fp-bar-slider {
  height: 0.9em; }
.flowplayer.fp-full .fp-timeline {
  position: absolute;
  height: .3em;
  bottom: 2.8em;
  margin: 0;
  width: 100%;
  border-radius: 0;
  overflow: inherit;
  left: 0; }
.flowplayer.fp-full .fp-timeline:before {
  content: "";
  width: 100%;
  height: 1.2em;
  position: absolute;
  top: -1.2em;
  left: 0; }
.flowplayer.fp-full .fp-controls {
  height: 2.8em; }
.flowplayer.fp-full .fp-controls:hover .fp-timeline {
  height: 1em; }
.flowplayer.fp-full .fp-volume {
  margin-right: auto; }
.flowplayer.fp-full .fp-duration, .flowplayer.fp-full .fp-remaining {
  margin-left: 0; }
.flowplayer.fp-full .fp-duration:before, .flowplayer.fp-full .fp-remaining:before {
  position: relative;
  content: "/";
  left: -.3em; }
.flowplayer.fp-minimal .fp-controls {
  background-color: transparent !important; }
.flowplayer.fp-minimal .fp-controls > * {
  display: none; }
.flowplayer.fp-minimal .fp-header > * {
  display: none; }
.flowplayer.fp-minimal .fp-header .fp-fullscreen {
  display: inherit; }
.flowplayer.fp-minimal .fp-timeline {
  height: .3em;
  max-width: 12em;
  width: 100%;
  display: block;
  margin: 0 auto;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -moz-box-flex: 0;
  -ms-flex: none;
  flex: none; }
.flowplayer.fp-playful .fp-color, .flowplayer.fp-playful .fp-color-play {
  background-color: #ec6c4c;
  fill: #ec6c4c; }
.flowplayer.fp-playful .fp-controls {
  background-color: #006680; }
.flowplayer .fp-selected:before {
  content: "✓ "; }
.flowplayer .fp-menu {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: .24em;
  position: absolute;
  width: 8em;
  right: .5em;
  z-index: 2;
  font-size: 90%;
  -webkit-transition: opacity .2s, height .2s, -webkit-transform .2s;
  transition: opacity .2s, height .2s, -webkit-transform .2s;
  transition: opacity .2s, transform .2s, height .2s;
  transition: opacity .2s, transform .2s, height .2s, -webkit-transform .2s;
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  bottom: -100%;
  height: 0;
  filter: alpha(opacity=0) !important;
  opacity: 0 !important; }
.flowplayer .fp-menu.fp-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  height: auto;
  bottom: 3.2em;
  filter: alpha(opacity=100) !important;
  opacity: 1 !important; }
.flowplayer .fp-menu > * {
  display: block;
  padding: .4em 1.2em;
  text-align: right; }
.flowplayer .fp-menu strong {
  display: block;
  padding: .7em 1.2em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: default;
  font-size: 90%; }
.flowplayer .fp-menu a {
  color: rgba(255, 255, 255, 0.8); }
.flowplayer .fp-menu a:hover:not(.fp-selected) {
  background-color: #000;
  color: #fff; }
.flowplayer .fp-menu .fp-icon {
  cursor: pointer; }
.flowplayer .fp-menu .fp-icon:before {
  margin-right: .1em;
  font-size: 1.5em;
  vertical-align: -0.1em; }
.flowplayer .fp-qsel-menu {
  width: auto; }
.flowplayer .fp-context-menu {
  width: auto;
  right: auto;
  bottom: auto !important;
  display: none; }
.flowplayer .fp-context-menu.fp-active {
  display: block; }
.flowplayer .fp-context-menu a, .flowplayer .fp-context-menu strong {
  text-align: left;
  color: #fff;
  white-space: nowrap; }
.flowplayer .fp-share-menu {
  width: 9em;
  padding-bottom: 0.2em; }
.flowplayer .fp-share-menu.fp-active {
  bottom: auto; }
.flowplayer .fp-share-menu a, .flowplayer .fp-share-menu strong {
  text-align: left; }
.flowplayer.fp-outlined .fp-play svg.fp-play-rounded-fill, .flowplayer.fp-minimal .fp-play svg.fp-play-rounded-fill, .flowplayer.fp-outlined .fp-play svg.fp-pause-rounded-fill, .flowplayer.fp-minimal .fp-play svg.fp-pause-rounded-fill, .flowplayer.fp-outlined .fp-play svg.fp-loading-rounded-fill, .flowplayer.fp-minimal .fp-play svg.fp-loading-rounded-fill, .flowplayer.fp-outlined .fp-pause svg.fp-play-rounded-fill, .flowplayer.fp-minimal .fp-pause svg.fp-play-rounded-fill, .flowplayer.fp-outlined .fp-pause svg.fp-pause-rounded-fill, .flowplayer.fp-minimal .fp-pause svg.fp-pause-rounded-fill, .flowplayer.fp-outlined .fp-pause svg.fp-loading-rounded-fill, .flowplayer.fp-minimal .fp-pause svg.fp-loading-rounded-fill, .flowplayer.fp-outlined .fp-waiting svg.fp-play-rounded-fill, .flowplayer.fp-minimal .fp-waiting svg.fp-play-rounded-fill, .flowplayer.fp-outlined .fp-waiting svg.fp-pause-rounded-fill, .flowplayer.fp-minimal .fp-waiting svg.fp-pause-rounded-fill, .flowplayer.fp-outlined .fp-waiting svg.fp-loading-rounded-fill, .flowplayer.fp-minimal .fp-waiting svg.fp-loading-rounded-fill {
  display: none; }
.flowplayer.fp-outlined .fp-play svg.fp-play-rounded-outline, .flowplayer.fp-minimal .fp-play svg.fp-play-rounded-outline, .flowplayer.fp-outlined .fp-play svg.fp-pause-rounded-outline, .flowplayer.fp-minimal .fp-play svg.fp-pause-rounded-outline, .flowplayer.fp-outlined .fp-play svg.fp-loading-rounded-outline, .flowplayer.fp-minimal .fp-play svg.fp-loading-rounded-outline, .flowplayer.fp-outlined .fp-pause svg.fp-play-rounded-outline, .flowplayer.fp-minimal .fp-pause svg.fp-play-rounded-outline, .flowplayer.fp-outlined .fp-pause svg.fp-pause-rounded-outline, .flowplayer.fp-minimal .fp-pause svg.fp-pause-rounded-outline, .flowplayer.fp-outlined .fp-pause svg.fp-loading-rounded-outline, .flowplayer.fp-minimal .fp-pause svg.fp-loading-rounded-outline, .flowplayer.fp-outlined .fp-waiting svg.fp-play-rounded-outline, .flowplayer.fp-minimal .fp-waiting svg.fp-play-rounded-outline, .flowplayer.fp-outlined .fp-waiting svg.fp-pause-rounded-outline, .flowplayer.fp-minimal .fp-waiting svg.fp-pause-rounded-outline, .flowplayer.fp-outlined .fp-waiting svg.fp-loading-rounded-outline, .flowplayer.fp-minimal .fp-waiting svg.fp-loading-rounded-outline {
  display: block; }
.flowplayer.fp-outlined .fp-airplay:before, .flowplayer.fp-minimal .fp-airplay:before {
  content: "\e201"; }
.flowplayer.fp-outlined .fp-fullscreen:before, .flowplayer.fp-minimal .fp-fullscreen:before {
  content: "\e202"; }
.flowplayer.fp-outlined .fp-checked:before, .flowplayer.fp-minimal .fp-checked:before {
  content: "\e203"; }
.flowplayer.fp-outlined .fp-embed:before, .flowplayer.fp-minimal .fp-embed:before {
  content: "\e204"; }
.flowplayer.fp-outlined .fp-chromecast:before, .flowplayer.fp-minimal .fp-chromecast:before {
  content: "\e205"; }
.flowplayer.fp-outlined .fp-email:before, .flowplayer.fp-minimal .fp-email:before {
  content: "\e206"; }
.flowplayer.fp-outlined .fp-playbtn:before, .flowplayer.fp-minimal .fp-playbtn:before {
  content: "\e207"; }
.flowplayer.fp-outlined .fp-share:before, .flowplayer.fp-minimal .fp-share:before {
  content: "\e209"; }
.flowplayer.fp-outlined .fp-share2:before, .flowplayer.fp-minimal .fp-share2:before {
  content: "\e20a"; }
.flowplayer.fp-outlined .fp-twitter:before, .flowplayer.fp-minimal .fp-twitter:before {
  content: "\e20c"; }
.flowplayer.fp-outlined .fp-facebook:before, .flowplayer.fp-minimal .fp-facebook:before {
  content: "\e20a"; }
.flowplayer.fp-outlined .fp-volumebtn:before, .flowplayer.fp-minimal .fp-volumebtn:before {
  content: "\e20b"; }
.flowplayer.fp-outlined .fp-volume-off:before, .flowplayer.fp-minimal .fp-volume-off:before {
  content: "\e20d"; }
.flowplayer.fp-outlined .fp-unload:before, .flowplayer.fp-minimal .fp-unload:before {
  content: "\e20f"; }
.flowplayer.fp-outlined .fp-prevbtn:before, .flowplayer.fp-minimal .fp-prevbtn:before {
  content: "\e211"; }
.flowplayer.fp-outlined .fp-nextbtn:before, .flowplayer.fp-minimal .fp-nextbtn:before {
  content: "\e212"; }
.flowplayer .fp-color {
  background-color: #00abcd;
  fill: rgba(0, 0, 0, 0.2); }
.flowplayer .fp-color-play {
  fill: transparent; }
.flowplayer.is-rtl {
  direction: rtl; }
.flowplayer.is-rtl .fp-icon {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
.flowplayer.-grayscale video.fp-engine {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }
.flowplayer.-sepia video.fp-engine {
  -webkit-filter: sepia(100%);
  filter: sepia(100%); }
.flowplayer.-blur video.fp-engine {
  -webkit-filter: blur(5px);
  filter: blur(5px); }

.fp-filters {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  position: absolute; }