.blue-skin{

}

.red-skin{

}

.green-skin{

}

.purple-skin{

}

.dark-skin{

}

.grey-skin{

}

.mdb-skin{

}

.deep-orange-skin{

}

.graphite-skin{

}

/* cs-skin */


.cs-skin{
    .jumbotron, .author-box, .card, .list-group, .popover, .navbar, .tag, .chip, .pagination .active .page-link, .btn, .pager li a, .btn-floating, .social-list, .card-wrapper .front, .card-wrapper .back, .card-cascade.wider .card-block, .card-cascade-2 .card-block, .side-nav, #toast-container > div, .section-blog-fw .view, .pricing-card .price .version{
        box-shadow: @theme-box-shadow;
    }
}

