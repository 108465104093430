@sidebar-widget-text-font: ~"200 1rem/1.3" @font-main;



/* custom css start*/
.csProfile__drag-image {
  width: 120px;
  height: 120px;
  text-align: center;
  .round-borders(50%);
  position: relative;

  .csProfile__pic-thumb {
    font-size: 12px;
    line-height: 14px;
    display: block;
    width: 110px;
    height: 110px;
    padding: 0;
    text-align: center;
    border: 2px dashed @lightGrey;
    margin: auto;
    position: relative;
    z-index: 20;
    top: 5px;
    .round-borders(100%);

  }
  .csProfile_pic-txt, .csProfile_no-pic-txt {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    position: relative;
    z-index: 12;
    display: block;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    position: absolute;
    margin: 0;
  }
  .csProfile_pic-avtar {
    display: block;
    position: absolute;
    font-size: 5rem;
    color: rgba(214, 214, 214, 0.35);
    left: 0;
    right: 0;
    top: 15px;
    margin: auto;
    z-index: 5;
    .box-sizing;
    .transition(color, 250ms, ease);
  }
}

.csAccount__image {



  .avatar {
    margin: 0 auto;
  }
  &.missing {
    :not(.dropped) .no-userpic:hover,
    :not(.dropped).hover .no-userpic {
      cursor: pointer;
      -webkit-animation: spin 3s linear infinite; /* Safari 4+ */
      -moz-animation: spin 3s linear infinite; /* Fx 5+ */
      -o-animation: spin 3s linear infinite; /* Opera 12+ */
      animation: spin 3s linear infinite;
    }
    .progress,
    .upload,
    .filereader,
    .formdata,
    .pic-remove,
    .pic-crop,
    .pic-cancel,
    .pic-submit {
      display: none;
    }
    .no-userpic,
    .csProfile_pic-avtar,
    .csProfile_pic-txt {
      display: block;
    }
  }
  &.dropped {
    .no-userpic {
      color: @white;
      border-width: 2px;
      -webkit-animation: spin 3s linear infinite; /* Safari 4+ */
      -moz-animation: spin 3s linear infinite; /* Fx 5+ */
      -o-animation: spin 3s linear infinite; /* Opera 12+ */
      animation: spin 3s linear infinite;
      font-size: 0;
    }
    .upload,
    .filereader,
    .formdata,
    .pic-remove,
    .pic-crop,
    .pic-cancel,
    .pic-submit,
    .csProfile_pic-txt {
      display: none;
    }
    .progress,
    .no-userpic,
    .csProfile_pic-avtar,
    .csProfile_pic-txt {
      display: block;
    }
  }
  &.isset {
    .progress,
    .upload,
    .filereader,
    .formdata,
    .pic-cancel,
    .pic-submit,
    .no-userpic,
    .csProfile_pic-avtar,
    .csProfile_pic-txt,
    .csProfile_no-pic-txt{
      display: none;
    }

    .pic-crop,
    .pic-remove {
      display: inline-block;
    }
  }
  &.crop-view {
    .csAccount__image__holder {
      width: 280px;
      height: 100%;
      margin: 0 auto 0 auto;
      padding: 0;
      text-align: center;
      border-radius: 0;
      .box-shadow(none);
      position: relative;
      .jcrop-holder div {
        .round-borders(50%);
      }
    }

    .progress,
    .upload,
    .filereader,
    .formdata,
    .pic-crop,
    .pic-remove {
      display: none;
    }

    .pic-cancel,
    .pic-submit {
      display: inline-block;
    }

  }
}

.author-box {
  padding: 2rem;
  a {
    color: @darkGrey;
  }
  p {
    color: @midGrey;
  }
}

.gh-count {
  position: relative;
  min-width: 15px;
  min-height: 18px;
  text-align: center;
  visibility: visible;
  border: @white solid 1px;
  border-radius: 3px;
  margin-left: 4px;
  padding:0 5px;
  i {
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    margin: -4px 0 0 -4px;
    line-height: 0;
    border: 4px transparent solid;
    border-left: 0;
    border-right-color: @white;
    zoom: 1;
  }
  u {
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    margin: -4px 0 0 -4px;
    line-height: 0;
    border: 4px transparent solid;
    border-left: 0;
    border-right-color: @white;
    zoom: 1;
  }
  a {
    padding: 0 0px 0 1px;
    color: @white;
  }
}
.gh-count__grey {
  position: relative;
  text-align: center;
  visibility: visible;
  border: @lightGrey solid 1px;
  border-radius: 3px;
  padding:1px 3px;
  font-size:0.8rem;
  i {
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    margin: -4px 0 0 -4px;
    line-height: 0;
    border: 4px transparent solid;
    border-left: 0;
    border-right-color: @lightGrey;
    zoom: 1;
  }
  u {
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    height: 0;
    margin: -4px 0 0 -4px;
    line-height: 0;
    border: 4px transparent solid;
    border-left: 0;
    border-right-color: @lightGrey;
    zoom: 1;
  }
  a {
    padding: 0 0px 0 1px;
    color: @midGrey;
  }
}


.tab-content{
  z-index: inherit;
}

/* custom css end*/



.csAccount__image,
.AccountTerms,
.AccountGroups {
  p {
    font: @sidebar-widget-text-font;
    color: @text-color;
  }
}



/* NG */


/* Public Profile Info Widget */

.csPublic--profile-info{
  .section img{
    box-shadow: none;
    border: 1px @border-gray solid;
  }

  .card-title{
    font: ~"400 1.25rem/1.2" @font-main;
    margin-top: 1rem;
  }
}


.csAccount__image .csAccount__image__ctrl{
  text-align: center;
  padding-top: 1rem;
}
