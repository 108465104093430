/* comment css*/
.csAttachment-list {
  li {
    overflow: hidden;
    .csAttachment-list-thumb {
      width: 50px;
      height: 50px;
      font-size: 1.8rem;
      text-align: center;
      border: 1px solid @lightGrey;
      img {
        .round-borders(0px);
      }
    }
    .mdb-lightbox{
      display: inline-block;
    }
  }
}

.comments-list {
  .level-2 {
    margin-left: 7rem !important;
  }
  .level-3 {
    margin-left: 9rem;
  }
  .level-4 {
    margin-left: 12rem;
  }
}

.comment-msg{
  word-wrap: break-word;
}



/* NG */

@comment-form-avatar: none; // Change to "block" to make visible "none" to hide
@comment-form-bg: #fff;
@comment-form-text-color: #757575;

@comment-username-font:  ~"500 1rem/1.2" @font-main;
@comment-username-color: @darkGrey;


@comment-meta-font: ~"300 0.78rem/1.4" @font-main;
@comment-meta-color: inherit;

@comment-p-font: ~"300 1rem/1.4" @font-main;


.csComments{
  /* Form */

  .reply-form{
    a{
      text-decoration: none;
    }

    .row{
      .flex-display();
      .col-sm-2{
        display: @comment-form-avatar; 
      }
      .col-sm-10{
        flex: 1 1 100%;
      }
    }
    
    img{
      box-shadow: none;
    }
    textarea{
      padding: 1rem 0.5rem;
      background: @comment-form-bg;
      + label{
          color: @comment-form-text-color;
      }
    }
    input + label{
          color: @comment-form-text-color;
    }
  }

  .comments-list{
    a{
      text-decoration: none;
    }
    img{
      box-shadow: none;
     }
    .user-name{
      font-weight: 200;
      font: @comment-username-font;
      color: @comment-username-color;
    }
    .card-data{
      padding: 5px 0;
       ul li{
        font: @comment-meta-font;
      }
    }
    .comment-msg p{
      font: @comment-p-font;
    }
   
 }
}

