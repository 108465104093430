/* csMediaMeta */

.csMediaMeta {

.mce-table {
    display: table;
    width: 100%;
    text-align: left;
    font-size: 12px;
    vertical-align: middle;
    .mce-table-body { display: table-row-group;}

    .mce-table-row {
        display: table-row;
        height: 48px;
    }
    .mce-table-row:nth-child(even) {
        background: #F7F7F7;
    }
    .mce-table-cell {
        display: table-cell;
        vertical-align: middle;
    }
    .mce-cell-left {
        font-weight: bold;
        width: 300px;
        padding-left: 24px;
    }

}
div.paleGreyRows {
}

}
