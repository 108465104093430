.csTree {

    .csTree__label {
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: #2d2d2d;
        padding-left: 5px;
        vertical-align: top;

        &[aria-expanded="true"]{
            color: #2080d6;
        }
    }

    .csTree__label-pre {
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: #979797;
        padding-left: 5px;
        vertical-align: top;
    }

    .csTree__aid {
        font-family: "Roboto";
        font-size: 14px;
        line-height: 28px;
        font-weight: 400;
        color: #979797;
        padding: 0px 0 0 4px;
        vertical-align: middle;

        &:before {
            content: "[";
        }
         &:after {
            content: "]";
        }
    }

    .csTree__sub {
        width: 100%;
        padding-left: 20px;
    } 

    .csTree__slot {
        width: 100%;
        padding-left: 40px;
        > li > i.c {
            margin-left: -20px;
            line-height: 24px;
        }
        > li > .csTree__label {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #969696;
            padding: 0;

            &:hover {
                // color: #2d2d2d;
            }

            .csTree__label-pre {
                font-size: 14px;
                line-height: 24px;
                color: #969696;
            }

            &[aria-expanded="true"]{
                color: #2080d6;
                .csTree__label-pre {
                    // color: #2d2d2d;
                }
            }
        }

        .csTree__sub {
            > li {
                position: relative;
            }
            > li > .csTree__label {
                font-size: 14px;
            }
        }

        > li > ol.csTree__sub >li i.c {
            position: absolute;
            left: -40px;
            top: 2px;
        }
    }

    ol {
        list-style-type: decimal;
        padding-left: 40px!important;
        margin-bottom: 2px;
        //counter-reset: listCount;

        > li {
            list-style-type: decimal;
            list-style-position: outside;
            display: list-item;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #969696;
            //display: table;
            //counter-increment: listCount;
            //&::before {
            //    content: counter(listCount) ".";
            //    display: table-cell;
            //    text-align: right;
            //    padding-right: .3em;
            //    width: 10px;
            //}


        }
    }

    li {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        line-height: 26px;
        cursor: default;
        &:hover > .csTree__label {
            // color: black;
        }
        &.active {
            // > i.c { .transform(rotate(90deg)); color: #2d2d2d;}
        }
    }

    a.csTree__link {
       color: #474646;
       vertical-align: middle;
       
       &:hover {
        color: #2080d6;
       } 
    }

    /* icons */

    i {
        display: inline-block;
        font: normal normal normal 14px/1 'Font Awesome 5 Pro';
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        width: 20px;
        height: 20px;
        vertical-align: top;

     }

    i.c {
        color: #969696;
        line-height: 24px;
        .transition(all, 300ms, ease-out);
        vertical-align: top;
        font-weight: 600;

        &:before {
            content: "\f0da";
        }

        &[aria-expanded="true"]{
            .transform(rotate(90deg)); 
            color: #2d2d2d;
        }
    }

    i.l {
        height: 20px;
        line-height: 20px;
        width: 20px;
        font-size: 12px;
        vertical-align: text-bottom;

        &:before {
            content: "\f35d";
        }
    }

    i.n { background: url('/img/i-n.png') 3px 7px no-repeat; background-size: 70%;}
    i.d { background: url('/img/i-d.png') 3px 7px no-repeat; background-size: 70%;}
}

