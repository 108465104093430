@media screen and (min-width: 300px) and (max-width: 1200px) {
  h1, h2, h3, h4, h5, h6,
  .h1-responsive,
  .h2-responsive,
  .h3-responsive,
  .h4-responsive,
  .h5-responsive,
  .h6-responsive {
    font-weight: 300;
  }

  html {
    margin: 0px !important;
    padding: 0px !important;
    overflow-x: hidden;
  }

  .container {
    max-width: 1140px !important;
  }

  .comments-list, .reply-form {
    text-align: left;
  }

  .navbar {
    .button-collapse {
      font-size: 1.6rem;
    }
  }

}

.navbar-nav, .navbar-brand {
  display: none;
}
@media screen and (min-width: 768px) {
  .navbar-nav, .navbar-brand {
    display: block;
  }
}

@media screen and (min-width: 993px) {
  .navbar {
    .button-collapse {
      display: none !important;
    }
  }
}

@media screen and (max-width: 992px){
  header{
    .container{
      padding-right: 0rem;
      padding-left: 0rem;
      .navbar{
        .round-borders(0px);
      }
    }
  }
}


@media screen and (max-width: 1024px) and (min-width: 992px) {
  .video__embed {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
  }
  .navbar {
    .button-collapse {
      display: block !important;
    }
  }

  .navbar-nav, .navbar-brand {
    display: none;
  }

  header{
    .container{
      padding-right: 0rem;
      padding-left: 0rem;
      .navbar{
        border-radius: 0px;
        margin: 0 15px;
      }
    }
  }

  .modal-open {
    overflow: hidden !important;
  }
}

@media screen and  (max-width: 480px) {
  .csContentArea {
    width: 100%;
    /* Shariff */
    .shariff li.shariff-button {
      width: 100%;
      margin: 0;
    }
  }

  .csCarousel-media-issue {
    .indicatorWrapper {
      .carousel-indicators {
        li {
          .thumb {
            width: 80px;
          }
        }
      }
    }
  }

  footer {
    ul{
      &.csFooter-link {
        float: left;
        li {
          display: block;
          width: 100%;
          text-align: left;
          padding: 5px 0px;
        }
      }
    }
    &.page-footer {
      .footer-copyright {
        padding-right: .9375rem;
        padding-left: .9375rem;
        text-align: left;
      }
    }
    .csFooter-language-switcher {
      margin: 0 20px 10px 0;
      width: 100%;
      text-align: left !important;
      float: left;
    }
  }

}

@media screen and  (min-width: 768px) and (max-width: 991px ) {
  .video__embed {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
  }
  header{
    .container{
      .navbar{
        margin: 0 15px;
      }
    }
  }

  .mobile-menu-btn {
    display: block;
  }

  .navbar-nav, .navbar-brand {
    display: none;
  }

  aside {
    .card {
      .card-block {
        .icon-addon {
          width: calc(~'100% - 115px');
        }
        .csSelect-basket {
          width: calc(~'100% - 115px');
        }
        .dropdown-item {
          .csBtn__bookmark-btn {
            padding: 0;
            float: right;
          }
        }
      }
    }
  }

  .preloader-wrapper {
    &.csLoader-xs {
      width: 10px;
      height: 10px;
    }
  }

}

@media screen and (min-width: 481px) and (max-width: 767px) {
  footer {
    .csFooter-language-switcher {
      margin-bottom: 10px;
      display: block;
      text-align: center;
      float: inherit;

    }
  }
}

@media screen and (min-width: 360px) and (max-width: 767px) {
  .video__embed {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
  }
  .csMobile-top {
    margin-top: 15px;
  }

  header{
    .container{
      .navbar{
        margin: 0 15px;
      }
    }
  }

  aside {
    .card {
      .card-block {
        .dropdown-item {
          .icon-addon {
            width: calc(~'100% - 115px');
          }
          .csSelect-basket {
            width: calc(~'100% - 115px');
          }
        }
      }
    }
  }

  .csSorting {
    .csSorting-select {
      //margin-bottom: 10px;
    }
  }

  .preloader-wrapper {
    &.csLoader-xs {
      width: 10px;
      height: 10px;
    }
  }
  .navbar{
    .button-collapse{
      margin: 0px;
    }
    .navbar-nav{
      .nav-item{
        a{
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
  }

  .breadcrumb {
    font-size: 0.9rem;
    li::before {
      padding-right: .2rem !important;
      padding-left: .3rem !important;
    }
  }

  footer {
    ul {
      &.csFooter-link {
        display: inline-block;
        margin: 10px 0 0 auto;
        li {
          margin: 0 20px 10px 0;
        }
      }
    }

  }

  .csSorting {
    .csSorting-select {
      margin-left: 0px;
    }
  }

  .modal-open {
    .modal {
      margin: auto 5%;
    }
  }

  .modal-dialog {
    .modal-body{
      max-height: 450px;
      .csMedia-left{
        width: 100%;
      }
    }
  }

  .csCarousel-fullpage{
    .carousel-control{
      .icon-next{
        margin-right:0px;
        right:0;
      }
      .icon-prev{
        margin-left:0px;
        left:0;
      }
    }
    .carousel-indicators{
      top:auto;
      bottom:0px;
      margin-bottom: 10px;



    }
    .csCarousel-caption-right
    {
      position: relative;
      width:100%;
      .animated{
        top:0px;
        left:0;
        right:0;
        margin-top:0px;
        padding:0px 20px 20px 20px;
        position: relative;
        .h3-responsive{
          text-align: left;
        }
        p{
          text-align: left;
        }
        .btn{
          margin-left: 0px;
        }
      }
    }
  }

  .btn {
    padding: 0.85rem 1.5rem;
    &.csProduct__btn-overley{
      padding: 0;
    }
  }
  .card{
    &.csChat-room{
      .csCard-body{
        .csChat-list{
          width: 60px !important;
          &.csNewMessageUserList{
            width: 100% !important;
            border: 0;
          }
          .csFriend-list{
            li{
              .cs-private-msgDelete{
                right: 0px;
              }
            }
          }
        }
        .csChat-private-box{
          width: ~"calc(100% - 60px)" !important;
        }

        .csText-small {
          display: none;
        }
      }
      .chat-footer{
        position: absolute;
        right: 3px;
        height: 100%;
      }
    }
  }

  .card.csChat-room .csCard-body .csChat-list .csFriend-list li .cs-private-msgDelete{
    right: 0px !important;
  }
  .card.csChat-room .csCard-body .csChat-list .csFriend-list li:hover .cs-private-msgDelete{
    bottom: 6px !important;
  }
  .modal{
    &.csShalink-modal-wraper{
      .modal-dialog{
        .modal-body{
          overflow-y: initial;
        }
      }
    }
  }
}

@media screen and (min-width: 360px) and (max-width: 416px) {

  .csMedia-right, .csMedia-left{
    .flowplayer .fp-controls > *{
      margin: 0 .15em;
    }
    .flowplayer .fp-volume{
      display: none;
    }
  }

  .card{
    &.csChat-room{
      .csCard-body{
        .csChat-private-box{
          .csChat-1{
            .csChat-body{
              .header{
                small{
                  float: none;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .navbar {
    form {
      input {
        width: 120px;
      }
    }
  }
}

@media screen and (min-width: 360px) and (max-width: 575px) {
  .csWidget__list{
    li{
      &.active{
        label{
          .csWidget__label{
            color: @font-base-color;
          }
        }
      }
    }
  }
}
