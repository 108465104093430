/* Items List*/

/* NG */


@items-headline-font: ~"400 1.125rem/1.4" @font-main;
@items-headline-color: #424242;
@items-headline-hover-color: #373a3c;

@items-text-font: ~"300 1rem/1.4" @font-main;
@items-text-font--large: ~"300 1rem/1.4" @font-main;
@items-text-color: #373a3c;


@items-meta-font: ~"300 0.78rem/1.4" @font-main;
@items-meta-color: #373a3c;
@items-meta-hover-color: #373a3c;

@items-text-align: center;


/* NG */



.csItems {

  /* Text align*/
  .text-align{
    text-align: @items-text-align;
  }

  /* Grid List align */
  .csGrid--list .text-align{
    text-align: left;
  }

  /* Card */

  .card{
    border: 0;
  }
  &.csProductSlider .card{
    border: 1px #e5e5e5 solid
  }
  /* Headline */
  .card-title {
    font: @items-headline-font;
    color: @items-headline-color;
    margin: 0;
      
    a {
      color: inherit;
      text-decoration: none;
      &:hover{
          color: @items-headline-hover-color;
        }
    }
  }

  /* Text */
  .card-text {
      font: @items-text-font;
      color: @items-text-color;
      b{
        font-weight: 600;
      }
  }

  /* Meta */
  .csCard__ctrl {
      font:@items-meta-font;
      color: @items-meta-color;
      a{
        color: @items-meta-color;
        &:hover{
          color: @items-meta-hover-color;
        }
      }
  }

  /* Footer */
  .card-footer{
    position: relative;
  }



}


/* Product Grid List */

.csItems .csGrid--list .csSpacer{
    margin: 0;
    .card{
      padding: 0;
      border-bottom: 1px @border-gray solid;
    }
    .csThumbholder{
      border: 1px @border-gray solid!important;
    }
}


/* Product Search Cards */

@items-card-headline-font: ~"500 1rem/1.4" @font-main;
@items-card-list-font: ~"400 0.875rem/1.4" @font-main;


.csItems.csItems--cards{


  .card{
    border: 1px @border-gray solid;
   .card-title{
      font: @items-card-headline-font;
      text-align: left;
      margin-top: 1.25rem;
    }
    .list-inline {
      float: right;
    }
    .list-inline li{
      margin: 0;
      span{
        font: @items-card-list-font;
      }
      i{
        color: #f1d81c;
      }
    }

    .price {
      color:@text-color;
      margin-top: 5px;
      font: 600 1rem/1rem "Muli";
      float: left;
    }
  
    .price.price--retail.price--offerd {
        text-decoration: line-through;
        color:@text-color-light;
        font: 400 0.875rem/1rem "Muli";
        & + .price-offer {
          margin-top: 0;
        }
    }
    .price.price--offer {
        clear: both;
    }

    .card-footer{
      border: 0;
      padding: 1.25rem 0 0;
    }
  }

  .csGrid--list .card{
    border: 0;
    border-bottom: 1px @border-gray solid;
    .card-title{
      margin-top: 0;
    }
    .card-footer{
      padding: 0.75rem 1.25rem 3rem;
    }
  }
}
