/* Head Intro align */

@media-h3-font:  ~"500 1rem/1.2" @font-main;


/* Article */

.csArticle__head-intro {
    h1, h2, h3, h4, h5{
        text-align: center;
        max-width: 900px;
        margin: 0 auto;
    }

    h1 {
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    h3 {
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    h1 + h3 {
        margin-top: -1rem; 
    }
}


.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xl-8{
    .csArticle__head-intro{
        h1, h2, h3, h4, h5{
            text-align: left;
        }
    }
}


/* Media */

.csMedia__head-intro{
     h3, h5{
        text-align: center;
    }
}


.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xl-8{
    .csMedia__head-intro{
        h3, h5{
            text-align: left;
        }
    }
}

.csMedia__head-intro h3{
    font: @media-h3-font!important;
    color: @darkGrey;
    margin: 0 0 1rem 0;
    padding: 0 0 1rem 0;
    border-bottom: 1px @border-gray solid;
}

.csMedia__head-intro h5{
    color: @darkGrey;
    margin: 0 0 1rem 0;
    padding: 0 0 1rem 0;
    border-bottom: 1px @border-gray solid;
}


/* Product Head Intro */

.csArticle__head-intro.csArticle__head--product{
    h1 {
        font:  ~"500 1.4rem/1.2" @font-main;
        text-align: left;
    }      
}
