/* csBreadcrumb.less */
.breadcrumb {
    background-color: transparent;
    padding: 1rem 0rem;
    margin: -30px 0 0 0;

    li,
    li.breadcrumb-item {
        display: inline-block;
        float: none;

        a {
            font: 300 1rem/1.2 @font-main;
        }
    }
    li:nth-child(2) {
        &:before {
            content: "";
            padding-left: 0px;
            color: @text-color-light;
            padding-right: 0px;
        }
    }

    span {
        font: 200 1rem/1.2 @font-main;
        margin-right: 10px;
        color: @text-color-light;
    }

}
