/* custom.less */

 @color-key: 					#004B87; 			// medice Accent colour
 // @color-key: 					#4585ad; 			// cs default Accent colour
// @font-main: 					"Muli";			// Main Font


// @text-color: 				#333;			// The main text color
// @text-color-light: 			#ccc;			// The lighter main text color


/* Links */

// @link-color: 				@color-key;
// @link-color--hover: 			@color-key;

// @link-deco: 					none;
// @link-deco--hover: 			underline;


/* Header */

// @theme-header-bg: 				#000;
// @theme-header-link-color: 		#fff;
