
.scrollbar-light-grey::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px; }

.scrollbar-light-grey::-webkit-scrollbar {
  width: 0.4rem;
  background-color: #F5F5F5; }

.scrollbar-light-grey::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

//overwrite css
.d-flex{
  display: flex !important;
}
.justify-content-between{
  justify-content:space-between !important;
  -webkit-box-pack: justify!important;
}

.badge {
  display: inline-block;
  padding: .25em;
  border-radius: 1rem;
  &.cs-dropdown-bubble{
    padding: 0.30em .60em;
    color: @white;
    line-height: 15px;
    font-size: 0.8rem;
    margin: 0;
    display: none;
    &.active{
      display: inline-block;
    }
  }
}
.csNotification{
  position: absolute;
  top: -8px;
  right:0;
  .badge{
    display: none;
    &.active{
      display: inline-block;
    }
  }
}

.privateMessage{
  .modal-body{
    .md-textarea{
      overflow-y: auto;
    }
  }
}



/* NG */


.csPrivateMsg .csChat-room{
  strong{
    font: 400 0.95rem/1.1 @font-main;
  }
  small{
    font: 200 0.75rem/1.5 @font-main;
  }
  .avatar{
    margin: 0 1.5rem 0 0;
    width: 2.7rem;
    height: 2.7rem;
    border: 1px @border-gray solid;
    background-color: #fff;
  }
  .csChat-body{
    width: 100%;
    p{
      margin-bottom: 0;
      font: 300 1rem/1.1 @font-main;
    }
  }
  .cs-private-msgDelete{
      .transition(all, 0.3s, ease-in-out);
      position: absolute;
      bottom: -40px;
      right: 0;
      opacity: 0.3;
      cursor: pointer;
      font-size: 14px;

    }
  .form-group{
    margin-bottom: 0.5rem;
     textarea{
      box-sizing: border-box;
    }
  }
  .csChat-header{
    border-bottom: 1px @border-gray solid;
    .btn{
      margin: 0.375rem 0.5rem;
    }
  }
  .csChat-list{
    .flex(1 1 33.33%);
    .csFriend-list li{
      border-bottom:  1px @border-gray solid;
      padding: 0.5rem;
      position: relative;
      overflow: hidden;
      .csChat-body small{
        display: block;
      }
      .avatar{
        margin-right: 0.5rem;
      }
      .cs-private-msgDelete{
        right: 10px;
      }
      a{
        text-decoration: none;
        width: 100%;
      }
      &:hover .cs-private-msgDelete{
        display: block;
        bottom: 19px;
        opacity: 1;
      }
      &.active{
        background: #f1f1f1;
      }
    }
    .d-flex{
      align-items: flex-start;
      align-content: flex-start;
    }
  }
  .csChat-private-box{
    .flex(1 1 66.66%);
    padding: 2rem 0.5rem 1rem 1rem;
    border-left: 1px @border-gray solid;
    .csChat-body{
      padding: 1.25rem 1.5rem 1.75rem;
      border: 1px @border-gray solid;
      position: relative;
      overflow: hidden;
      small{
        font: 400 0.74rem/2.2 "Muli";
        vertical-align: middle;
      }
      .cs-private-msgDelete i{
        padding: 0.5rem;
        background: #f1f1f1;
      }
      &:hover .cs-private-msgDelete{
        display: block;
        bottom: 0;
        opacity: 1;
      }
    }
  }


}

@media screen and (max-width: 767px){
  .csPrivateMsg .csChat-room .csChat-list{
    width: 60px;
    .flex(0 0 60px);
    .csChat-body{
      small,
      strong{
        display: none!important;
      }
    }
  }
  .csPrivateMsg .csChat-room .csChat-private-box{
    padding: 1rem 0.5rem 1rem 0.5rem;
  }

}

