/* csFooter.less */
@theme-footer-bg: #e6e6e6;
@theme-footer-link-color: @font-base-color;

/* Sticky Footer */

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 145px;
}

footer.page-footer {
    font-size: @basefont;
    background: @theme-footer-bg;

    position: absolute;
    bottom: 0;
    width: 100%;
    height: 145px;

    .footer-copyright {
        background: none;
        font-size: @basefont - 0.3rem;
        color: @theme-footer-link-color;
        letter-spacing: 1px;
        height: 32px;
        line-height: 32px;
    }
}



/* Footer Navigation */
footer.page-footer .csFootNav {
    margin: 0;

    .navbar-nav {
        .justify-content(center);
        .flex-display;
        margin: 0;

        li {
            float: none;
            color: @theme-footer-link-color;

            a {
                color: inherit;
            }

            a:hover {
              text-decoration: underline;
            }
        }
    }
}