/* ---- Thumbnail ---- */
    .csThumbholder {
      border: 1px solid #e6e6e6;
      display: block;
      position: relative;
      padding-top: 98.69%;
      i {
        font-size: 2rem !important;
        text-align: center;
        width: 35px;
        height: 35px;
        display: block;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        color: @grey;
        &.img-icon{
          width: auto;
          height: auto;
          font-size: 1.3rem !important;
          line-height: 1.9rem;
        }
      }
      img {
        padding: 0;
        margin: auto;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
      }

    }

/* img according to the size */
img {
  &.img-landscape {
    width: 100% !important;
    height: auto !important;
  }
  &.img-portrait,
  &.img-portait {
    height: 100% !important;
    width: auto !important;
    max-height: 470px;
    margin: auto; 
  }
  &.img-square {
    height: 100%;
    width: 100%;
  }
  &.img-icon {
    height: 35px !important;
    width: auto !important;
  }
}
i{
  &.csIcon__center{
    font-size: 2rem !important;
    text-align: center;
    width: 35px;
    height: 35px;
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    color: #999999;
  }
}

.csCompress-icon{
  padding: 20px;
  min-height: 300px;
  .rel{
    width: 35px;
    height: 35px;
    position: absolute;
    margin: auto;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
  }
}

/* ---- Thumbnail ---- */
