/* custom css start*/

.csProduct__btn-overley {
    background-color: rgba(255, 255, 255, 0.75);
    padding: 0;
    .round-borders(1px);
    width: 34px;
    height: 30px;
    display: block;
    position: relative;
    margin: 6px;
    border: none;

    i {
        color: #333;
    }
}

.csSlide-icon {
    &:before {
        content: '\e901';
        font-family: 'icomoon';
    }
}

.csProduct-additional__content-modal {
    .csProduct-additional__img {
        width: 50%;
        margin: 0px 20px 20px 0px;
        .box-sizing;
    }
}

.csDownload-list {
    li {
        margin-bottom: .6rem;

        a {
            color: @black;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

table {
    p {
        margin-bottom: 0;
    }
}

.accordion {
    border: 1px solid #eee;

    .panel {
        padding: 0px;

        &:last-child {
            border: 1px solid @white;
        }

        .panel-heading {
            color: @darkGrey  !important;

            .panel-title {
                margin-bottom: 0px;

                a {
                    color: @darkGrey  !important;
                    padding: .8rem .8rem;
                    display: block;
                }
            }
        }
    }

    .panel-collapse {
        padding: 0 0.8rem;

        .csCollaps-inner {
            padding: .5rem .8rem;
        }
    }
}



/* product specs */
.csProductspec .csSpecs {
    li:nth-child(even) {
        background: #f2f2f2;
    }

    li {
        padding: 10px;
    }

    li p {
        font-size: 1rem;
        margin: 0;
    }
}




/* Product sidebar widgets */

@product-title-font: ~"600 1.9rem/2rem" @font-main;

@product-price-font: ~"400 1.25rem/1.4rem" @font-main;
@product-offer-price-font: ~"500 1.5rem/1.7rem" @font-main;
@product-offer-price-color: @text-color;

@product-list-item-font: ~"500 1rem/1.2rem" @font-main;


/* Brand Logo */

.csProdBrand.card {
    border: 0;
    margin: 0 0 30px 0;

    .card-block {
        padding: 0;

        img {
            width: 50%;
        }
    }
}

/* Head Intro */

.csArticle__head-intro.csArticle__head--product h1 {
    font: @product-title-font !important;
}
.csArticle__head-intro.csArticle__head--product {

    h1,h2,h3,h4 {
        text-align: left;
    }

    h4 {
        font-size: 20px;
        line-height: 24px;
        color: @text-color-light;
    }

    h3 {
        font-size: 20px;
        line-height: 24px;
        color: @text-color;
    }
}

/* Price */

.csProduct.csProduct--price {
    ul li {
        display: inline-block;
        margin: 0 0.75rem 0.75rem 0;
        display: inline-block;
        width: 100%;

        p {
            font: @product-price-font;
            margin: 0;
        }

        &:first-child p {
            text-decoration: line-through;
            color: @text-color-light;
        }

        &:last-child p {
            text-decoration: none;
            font: @product-offer-price-font;
            color: @product-offer-price-color;
        }

    }
}

.csProduct.csProduct--price {
    p {
        font: ~"500 1.5rem/1.7rem" @font-main;
        margin: 0 0 1.5rem;
    }
    p.hasOffer {
        font: ~"400 1.25rem/1.4rem" @font-main;
        color: @text-color-light;
        text-decoration: line-through!important;
        margin: 0 0 5px;
    }
}

.csProduct.price--offer {
    margin: 0 0 1.5rem;

    p {
        font: ~"500 1.5rem/1.7rem" @font-main;
        color: @product-offer-price-color;
    }
}


/* Color */

.csProduct.csProduct--color {

    ul li {
        width: 40px;
        height: 40px;
        border: none;
        display: inline-block;
        margin: 0 0.75rem 0.75rem 0;

        img {
            border-radius: 0;
        }
    }
}

/* Size */

.csProduct.csProduct--size,
.csProduct.csProduct--main {
    ul {
        padding: 0;

        li {
            text-align: center;
            list-style: none;
            display: inline-block;
            border: @border-gray solid 2px;
            min-width: 45px;
            width: auto;
            padding: 2px 10px;
            margin: 0 0.75rem 0.75rem 0;

            p {
                font: 600 1rem/1.4rem "Muli";
                margin-bottom: 0
            }
        }
    }
}


/* Product Main */

.csProduct--main {
    ul li p {
        font: @product-list-item-font;
    }
}


.csProduct--size,
.csProduct--color,
.csProduct--price,
.csProduct--main,
.csProdBrand{
    margin: 0;

    .csWidget__title {
        border-bottom: none;
        margin: 0 0 0.75rem;
        padding: 0;
        color: @text-color-light;
    }

    ul {
        margin: 0 0 .75rem;
    }

}


/* End sidebar widgets */
