@fl-active-color: #358ed5;

@theme-font: "Muli";
@theme-nav-link-font: ~"300 20px/22px" @theme-font;
@fontawesome: "Font Awesome 5 Pro";

@theme-nav-active-bg: rgba(0,0,0,.1);

.csFlyout {
  display: none;
  height: 31px;
  margin-top: -3px;


  .csFlyout__link {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  > li {
    margin: 0 5px 0px 0;
  }


  > li.nav-item.show { // bs override
    background: transparent!important;
  }

  /* Main navigation item link  */
  > li.nav-item .nav-link {
    font: @theme-nav-link-font;
    color: @theme-header-link-color;

    padding: 12px 6px 15px 8px;
    margin: 0;
    border-radius: 0;
  }

  /* Main navigation item with flyout */
  > li.nav-item.hasFlyout .nav-link {
    border-radius: 0;
  }
  > li.nav-item.hasFlyout >.nav-link.active {
    background: @theme-nav-active-bg;
  }

  /* Main navigation item link active */
  > li.nav-item.show > .nav-link, li.nav-item.hasFlyout > .show {
    background: @theme-nav-active-bg !important;
  }

  /*Main nav item 1 level hover */
  > li.nav-item.hover > .nav-link {
    background: @theme-nav-active-bg;
  }

  /* Main nav item 1 level active */
  > li.nav-item.active > .nav-link {
    background: @theme-nav-active-bg!important;
  }

  .hasFlyout,
  .nav-item.nav-item--more {
    position: relative;
    z-index: 1;

    &:hover {
      z-index: 2;
    }

    &.active > .csFlyout__link {
      color: @theme-header-link-color!important;
    }
 
    >.nav-link,
    >.csFlyout__link {
      padding-left: 23px!important;
    }

    .subMenuCurrent{
       > .csFlyout__link {
        color: @theme-header-link-color!important;
      }
    }
  }

  .csFlyout__rail > .nav-item.hover > .csFlyout__link,
  .csFlyout__rail > .nav-item.show > .csFlyout__link {
    background: @theme-nav-active-bg;
  }

  /* main navi caret icon*/
  .hasFlyout:after,
  .nav-item.nav-item--more:after {
    content: "\f0d7";
    position: absolute;
    right: auto;
    left: 10px;
    top: 14px;
    height: auto;
    display: inline-block;
    font-family: @fontawesome;
    font-weight: 600;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    .transition(all, 300ms, ease);
    color: @theme-header-link-color;
    .transform(rotate(-90deg))
  }

  .csFlyout__rail .hasFlyout:after{
    top: 19px;
    right: auto;
    left: 20px;
    color: #fff;
  }

  .nav-item.show.nav-item--more:after,
  .nav-item.show.hasFlyout:after {
    .transform(rotate(0));
  }

  .nav-item.nav-item--more:hover:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 0;
    width: 0;
    margin: 0 auto;
    background: transparent;
    border-radius: 0%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 0 0 0;
    border-color: #465e22 transparent transparent transparent;
    z-index: 999;
  }

  .csFlyout__rail {
    .flex-display;
    .transition(transform, 300ms, ease);
    position: relative;

    > .nav-item {
      flex: 1 0 auto;
      padding: 0 10px;
    }

  }
  .nav-item.nav-item--more .subMenuCurrent > .csFlyout__link {
    color: #fff!important;
  }

  .csFlyout__extend {
    background: #34383F;
    position: fixed;
    left: 0;
    right: 0;
    padding: 0 0;
    top: 62px;
    max-width: 930px;
    margin: 0 auto;
    //overflow: hidden;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 4px 10px 0 rgba(0, 0, 0, 0.12);
    .justify-content(flex-start);
    .flex-display;
     display: none;

    .csFlyout__outlet { 
      top: 118px;
    }
    .csFlyout__extend-wraper{
      overflow: hidden;
      max-width: 1110px;
      display: block;
    }

    > li {
      margin-right: 5px;
    }

    &.collapse {
      display: none;
    }

    &.collapse.in {
      .flex-display;
    }

    .csFlyout__rail > .nav-item .csFlyout__link {
      padding: 8px 10px 8px;
      margin: 8px 0 8px 0;
      border-radius: 2px 2px 0 0;

      &.subMenuCurrent{
        background-color: rgba(0, 0, 0, 0.4) !important;
      }
    }
    .csFlyout__rail > .nav-item {
        &.subMenuCurrent > a.csFlyout__link{
          background-color: rgba(0, 0, 0, 0.4) !important;
        }
      .csFlyout__outlet{
        .csFlyout__link{
          &.subMenuCurrent{
            background-color: rgba(0, 0, 0, 0) !important;
          }
        }
      }
    }

    .hasFlyout:before,
    .nav-item.nav-item--more:before {
    }

    .hasFlyout:hover:before,
    .nav-item.nav-item--more:hover:before {
      border-color: #42581f transparent transparent transparent;
    }

    .csFlyout__rail > .nav-item:hover:before{
      border-color: #344619 transparent transparent transparent!important;
    }

    /* third level active */
    .csFlyout__rail > li.show {
      background: none!important;
      a {
        background: rgba(0,0,0,.5)!important;
      }
    }

  }

}

/* extend nav links */
header .csFlyout .csFlyout__extend .nav-item >a {
  color: #fff !important;
}


header .csFlyout.nav.navbar-nav {
  .csFlyout__link {
    display: block;
    color: #333;
  }

  /* more link */
  .nav-item.nav-item--more > .csFlyout__link {
    margin: 0;
    padding: 12px 6px 15px 8px;
    border-radius: 0;
    font: @theme-nav-link-font;
    color: @theme-header-link-color;
  }
  .nav-item.nav-item--more.show > .csFlyout__link {
    background: @theme-nav-active-bg;
  }

  /* second level link */
  .csFlyout__item > .csFlyout__link {
    color: #999;
    border-bottom: 1px solid #ccc;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
    &.subMenuCurrent{
      color: @fl-active-color!important;
    }

    &:hover {
      color: #111;
      border-bottom: 1px solid #666;
    }

     &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

  }

  .csFlyout__sub {
    margin: 0 0 30px;
    >.csFlyout__link.subMenuCurrent {
      color: @fl-active-color!important;
    }
  }

}

/* more */
.csFlyout .csFlyout__outlet {
  position: fixed;
  left: 0;
  right: 0;
  top: 62px;
  background: #ffffff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.16), 0 4px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 1rem;
  width: 100vw;
  max-width: 930px;
  margin: 0 auto;
  .flex-wrap(wrap);
  .flex-display;
  display: none;

  &.collapse {
    display: none;
  }

  &.collapse.in {
    .flex-display;
  }

  &.collapsing {
    transition-duration: 2s;
  }

  .csFlyout__item {
    .flex(0 0 20%);
    padding: 0 15px;

    .csFlyout__link {
      padding: .425rem 0;
      color: #333;
      &.subMenuCurrent{
        background: transparent !important;
      }
    }

  }

  /* third level link */
  .csFlyout__sub .csFlyout__link {
    font-size: 15px !important;
    line-height: 18px;
    color: #999999 !important;
    padding-top: .425rem;
    padding-bottom: .425rem;

    &:hover {
      color: #111111 !important;
    }

  }

}




.navbar.navbar-dark .breadcrumb a:hover,
.navbar.navbar-dark .navbar-nav .nav-item a:hover {
  color: #fff;
}


@media screen and (min-width: 1025px) {
  .csFlyout {
    display: inline-block;
  }
}


@media screen and (min-width: 1200px) {
  .csFlyout .csFlyout__outlet,
  .csFlyout .csFlyout__extend {
    max-width: 1110px;
  }
}


/* Flyout controls */
.csFlyout .csFlyout__ctrl-left,
.csFlyout .csFlyout__ctrl-right {
  position: absolute;
  width: 50px;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  padding: 8px;
  font-size: 20px;
  color: rgba(255,255,255,.75);
  cursor: pointer;
  text-align: center;

  &:before,
  &:after {
    display: inline-block;
    font: normal normal normal 14px/1 @fontawesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: relative;
    transform: translateY(-50%);
    height: 30px;
    top: 50%;
  }

  &:hover {
    color: rgba(255,255,255,1);
  }
}

.csFlyout .csFlyout__ctrl-left {
    left: 0;
    padding-right: 15px;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1d2123+0,34383f+100&1+0,1+30,0.75+60,0+100 */
    background: -moz-linear-gradient(left, rgba(29,33,35,1) 0%, rgba(36,40,43,1) 30%, rgba(43,47,52,0.75) 60%, rgba(52,56,63,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(29,33,35,1) 0%,rgba(36,40,43,1) 30%,rgba(43,47,52,0.75) 60%,rgba(52,56,63,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(29,33,35,1) 0%,rgba(36,40,43,1) 30%,rgba(43,47,52,0.75) 60%,rgba(52,56,63,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2123', endColorstr='#0034383f',GradientType=1 ); /* IE6-9 */

    &:hover {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3f729b+0,34383f+100&1+0,1+30,0.75+60,0+100 */
      background: -moz-linear-gradient(left, rgba(63,114,155,1) 0%, rgba(60,97,127,1) 30%, rgba(57,79,100,0.75) 60%, rgba(52,56,63,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(63,114,155,1) 0%,rgba(60,97,127,1) 30%,rgba(57,79,100,0.75) 60%,rgba(52,56,63,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(63,114,155,1) 0%,rgba(60,97,127,1) 30%,rgba(57,79,100,0.75) 60%,rgba(52,56,63,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f729b', endColorstr='#0034383f',GradientType=1 ); /* IE6-9 */
    }

    &:before{
      content: "\f100";
    }
}

.csFlyout .csFlyout__ctrl-right {
    right: 0;
    padding-left: 15px;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#34383f+0,1d2123+100&0+0,0.75+40,1+70,1+100 */
    background: -moz-linear-gradient(left, rgba(52,56,63,0) 0%, rgba(43,47,52,0.75) 40%, rgba(36,40,44,1) 70%, rgba(29,33,35,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(52,56,63,0) 0%,rgba(43,47,52,0.75) 40%,rgba(36,40,44,1) 70%,rgba(29,33,35,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(52,56,63,0) 0%,rgba(43,47,52,0.75) 40%,rgba(36,40,44,1) 70%,rgba(29,33,35,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0034383f', endColorstr='#1d2123',GradientType=1 ); /* IE6-9 */

    &:hover {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#34383f+0,3f729b+100&0+0,0.75+40,1+70,1+100 */
      background: -moz-linear-gradient(left, rgba(52,56,63,0) 0%, rgba(56,79,100,0.75) 40%, rgba(60,97,128,1) 70%, rgba(63,114,155,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(52,56,63,0) 0%,rgba(56,79,100,0.75) 40%,rgba(60,97,128,1) 70%,rgba(63,114,155,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(52,56,63,0) 0%,rgba(56,79,100,0.75) 40%,rgba(60,97,128,1) 70%,rgba(63,114,155,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0034383f', endColorstr='#3f729b',GradientType=1 ); /* IE6-9 */
    }

    &:before{
      content: "\f101";
    }
}


.csFlyout .nav-item--more .csFlyout__rail {
  display: flex!important;
}


.csFlyout.navbar-nav .nav-item.active {
  background: transparent!important; 
}

.csFlyout.navbar-nav .nav-item.no-hover a.nav-link {
  background: transparent!important; 
}

.csFlyout.navbar-nav .nav-item.no-hover.show a.nav-link {
   background: @theme-nav-active-bg!important;
}

