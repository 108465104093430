/* csLang  */

.csLang {
    float: right;
    padding: 7px 0;
    margin: 0;

    .nav-item {
        display: inline-block;
        color: @theme-header-link-color;

        a {
            color: inherit;
        }
    }
}