  /* ==================  overWrite start  ============================*/
  header {
      .avatar-icon {
          .round-borders(50%);
          width: 30px;
          display: inline-block;
          height: 30px;
          line-height: 27px;
      }
      .avatar img {
        height: 30px;
        width: 30px;
        display: block;
      }
  }




  /* NG */
  @theme-header-bg: #fff;
  @theme-header-link-color: #373a3c;
  
  @theme-border-color: #e5e5e5;

  .mobile-menu-btn {
      font-size: 1.5rem;
      float: left;
      color: @theme-nav-link-font;
  }


  .page-header {
      background: @theme-header-bg;

      .navbar-brand img {
          height: 30px;
      }

      .navbar {
          border-bottom: 1px @theme-border-color solid;
          padding: 1rem 0;
      }
  }

  /* Quicksearch */
  .csQuicksearch {
      float: right;
      padding-top: .4rem;
      padding-bottom: .4rem;
      margin: 0 1rem 0 0;
      display: inline-block;
      position: relative;

      input[type=search] {
          background-color: transparent;
          .round-borders(0px);
          outline: none;
          .transition(all, .3s);
          height: 2rem;
          -webkit-appearance: none;

          &:focus:not([readonly]) {
              border-bottom: 1px solid @selectblue;
              .box-shadow(0 1px 0 0 @selectblue);
          }
      }

      i {
          vertical-align: middle;
          color: @theme-header-link-color;
      }
  }

    /* Quickbar */

    .csQuickbar{
      padding: 7px 0;
      .item--avatar{
        .avatar-icon{
          margin-top: -1px;
        }
        img{
          width: 25px;
          height: 25px;
        }
      }
    }
