/* Cookie Policy Disclaimer */

@cp-font-family: "Roboto",sans-serif;
@cp-link-color: #004B87;
@cp-text-color: #373a3c;
@cp-headline-color: #373a3c;
@cp-btn-primary-bg: #004B87;
@cp-info-border-color: #e5e5e5; 
@cp-info-bg-color: #f2faff; 

body.csPolicy {
    overflow: hidden!important;
}
body.csPolicy.csPolicy--init .csCookiePolicy:before{
        background: rgba(0, 0, 0, 0.4);
}
.csCookiePolicy {
    max-width: 100%;
    width: 460px;
    position: fixed;
    box-sizing: border-box;
    z-index: 99999;
    display: none;

    left: 0;
    right: 0;
    top: 20px;
    bottom: 0;
    margin: auto;
    max-height: 80%;

    .csCP__inner {
        background: #fff;
        position: relative;
        z-index: 99999;
        padding: 30px;
        background: white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
        border-radius: 2px;
    }

    &:before {
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 9998;
    }

    .csCP__hl {
        font: 400 ~"28px/28px" @cp-font-family;
        color: @cp-headline-color;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .csCP__head {
        margin: 0 0 15px;
    }

    .csCP__body {
        p {
            color: @cp-text-color;
            font: 300 ~"18px/22px" @cp-font-family;
            text-align: center;
            margin-bottom: 15px;

            a {
                color: @cp-link-color;
                &:hover {
                    color: @cp-link-color;
                    text-decoration: underline;
                }
            }
        }

        .csCP__btn {
            display: block;
            width: 100%;
            background: @cp-btn-primary-bg;
            color: #fff;
            font-size: 16px;
            padding: 8px;
            .box-sizing;
            border: none;
            cursor: pointer;
            margin: 0 0 10px 0;

            &:hover {
                text-decoration: underline;
            }
            i {
                margin: 0 5px 0 0;
            }
        }
        .csCP__btn.csCP__btn--cfg {
            border: 1px #e5e7e8 solid!important;
            background: #fff!important;
            color: #4f4f4f!important;
            font-family: @cp-font-family;
        }

        .csCP__btn--agree-cfg {
            display: none;
        }
    }

    .csCP__close {
        position: absolute;
        right: 20px;
        top: 20px;
        display: none;
    }

    .con__t {
        .flex-display;
        .con__l {
            .flex(1 1 auto);
        }
        .con__r {
            .flex(0 0 auto);
        }
    }
}

.csCookiePolicy .csCP__cfg {
    border-top: 1px #e5e7e8 solid;
    z-index: 9999;
    display: none;
    position: relative;

    .csCP__inner {
        overflow-y: auto;
        height: 50vh;
    }

    fieldset {
        border-bottom: 1px #e5e7e8 solid;
        margin: 0 0 10px;
        padding: 0 0 10px;

        &:last-of-type {
            border-bottom: none;
        }
    }

    table {
        border: none;
        margin: 0;
        width: 100%;
        tr td,
        tr th {
            font: 300 ~"14px/16px" @cp-font-family;
            padding: 5px 10px;
            color: @cp-text-color;

            p{
                margin-bottom: 0;
                font: inherit;
                color: inherit;
                text-align: left;
            }
            &:first-child { border-left: none; }
            &:last-child { border-right: none; }
        }
        tr:last-child td {
            border-bottom: none;
        }
        tr th {
            background-color: #f1f1f1;
        }
    }

    .opt__con {
        margin: 0 0 15px;
        display: none;
    }

    .opt__toggle {
        font: 400 14px/28px @cp-font-family;
        color: @cp-link-color;
        display: inline-block;
        background: white;
        padding: 0 5px 5px;
        margin: 0;
        position: relative;
        cursor: pointer;
        

        &:hover {
            color: @cp-link-color;
            text-decoration: underline;
        }

        i {
            margin-right: 8px;
            .transition(all, 200ms, ease-out);
        }

        .toggle__label-hide {
            display: none;
        }

        &.open {
            +.opt__con {
                display: block;
            }

            >.fas {
                .transform(rotate(90deg));
            }

            .toggle__label-hide {
                display: inline-block;
            }
            .toggle__label-show {
                display: none;
            }
        }
    }

    .opt__switch {
        position: relative;
        display: inline-block;
        width: 60px;
        margin: 3px 0 0 0;
        height: 28px;
    }

    .opt__desc {
        font: 300 ~"16px/20px" @cp-font-family;
        color: @cp-text-color;
        margin: 0 0 10px;
        padding: 0;
    }

    .opt__hl {
        margin: 0;
        padding: 0;
        font: 400 ~"24px/32px" @cp-font-family;
        color: @cp-headline-color;
    }

    .opt__cb {

        &:checked+.opt__slide {
            background-color: #2196F3;
        }

        &:focus+.opt__slide {
            box-shadow: 0 0 1px #2196F3;
        }

        &:disabled+.opt__slide {
            background-color: #abc8e2;
        }

        &:checked+.opt__slide:before {
            -webkit-transform: translateX(30px);
            -ms-transform: translateX(30px);
            transform: translateX(30px);
        }
    }

    .opt__slide {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        .transition(all, 400ms, ease);
        border-radius: 28px;

        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }
}


@media screen and (max-width: 768px){
    .csCookiePolicy {
        max-height: 95%;
        top: 0;
        max-width: 95%;

        .csCP__cfg .csCP__inner {
            height: ~"calc(100vh - 300px)";
        }
    }
}

#hs-eu-cookie-confirmation {
 left: -999999px!important;
}


.csCspInfo {
    border: 1px @cp-info-border-color solid;
    margin: 0 0 60px;
    padding: 30px;
    background: @cp-info-bg-color;

    h2 {
        margin: 0 0 10px;
        padding: 0;
    }
    p {
        color: @cp-text-color;
    }

    .csCspInfo__link {
        color: @cp-link-color;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}