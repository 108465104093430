/* csPage.less */

.main-wrapper {
    overflow: hidden;
    margin-top: 70px;
    padding-top: 30px;
}

/* set full-height for page-container */
.main-wrapper >.container {
	height: ~"calc(100vh - 220px)";
}