/* Pagination */

/* NG */

@pagination-align: center;

@pagination-text-color: #4B515D;

@pagination-bg--active: #f1f1f1;
@pagination-text-color--active: #000;



.pagination{
  width: 100%;
  text-align: @pagination-align;
  ul{
    display: inline-block;
  }
  .page-item{
  	color: @pagination-text-color;
  	.page-link{
  		&:hover,
	    &:focus{
	    	background: @pagination-bg--active;
	    	color: @pagination-text-color--active;
	    }
  	}
	  &.active .page-link{
	    background: @pagination-bg--active;
	    color: @pagination-text-color--active;
	    &:hover,
	    &:focus{
	    	background: @pagination-bg--active;
	    	color: @pagination-text-color--active;
	    }
	  }
	}
}

/* Pagination end */