/* csQuickbar  */

.csQuickbar {
  display: inline-block;
  float: right;

  .nav-item {
    display: inline-block;
    margin: 0 0.5rem 0 0;
    color: @theme-header-link-color;

    a {
    	color: inherit;
    }
  }
}


.csQuickbar .dropdown-toggle::after {
	margin-left: 0;
}
